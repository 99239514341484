import { tutorialGirlImg } from "assets/images";
import Popup from "components/layout/Popup/Popup";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "./TutorialPopup.scss";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";
import MainBtn from "components/layout/MainBtn/MainBtn";

const { closeText, nextText } = TRANSLATIONS.tutorialPopup;

const TutorialPopup = ({ active, onClose, slides }) => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const [activeIndex, setActiveIndex] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [typingSkiped, setTypingSkiped] = useState(false);
  const nextButtonRef = useRef(null);
  const mainRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      if (mainRef?.current) {
        setImgHeight(
          window.innerHeight -
            mainRef?.current.getBoundingClientRect().height -
            30
        );
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainRef.current]);


  return (
    <Popup activePopup={active} onClose={onClose}>
      <div className="tutorialPopup container">
        <img
          src={tutorialGirlImg}
          alt="firl"
          className="tutorialPopup__img"
          style={{
            maxHeight: mainRef?.current ? `${imgHeight}px` : "0px",
          }}
        />
        {/* <div className="tutorialPopup__img-wrapper">
        </div> */}
        <div className="tutorialPopup__main" ref={mainRef}>
          <Swiper
            className="tutorialPopup__slider"
            slidesPerView={1}
            spaceBetween={40}
            onSlideChange={handleSlideChange}
            modules={[Navigation]}
            onBeforeInit={(swiper) => {
              if (typeof swiper.params.navigation === "object")
                swiper.params.navigation.nextEl = nextButtonRef.current;
            }}
            navigation={{
              nextEl: nextButtonRef.current,
              // prevEl: styles.projectsHeader__arrowBtn_next,
            }}
          >
            {slides.map((slide, index) => {
              return (
                <SwiperSlide key={index} onClick={() => setTypingSkiped(true)}>
                  {slide.img && (
                    <img
                      src={slide.img}
                      alt=""
                      className={`tutorialPopup__main-img ${
                        index === activeIndex ? "active" : ""
                      }`}
                    />
                  )}
                  <div className="tutorialPopup__main-texts-wrapper">

                  <p
                    className={`tutorialPopup__main-text ${
                      activeIndex === index ? "active" : ""
                    }`}
                  >
                    {slide.text.split(" ").map((word, wordIndex) => (
                      <span key={wordIndex}>
                        {word.split("").map((letter, letterIndex) => {
                          const globalIndex =
                            slide.text
                              .split(" ")
                              .slice(0, wordIndex) // Take all previous words
                              .join("").length + // Count their characters
                            wordIndex + // Account for spaces
                            letterIndex; // Add the current letter index
                          return (
                            <span
                              key={letterIndex}
                              style={{
                                animationDelay: !typingSkiped
                                  ? `${globalIndex * 0.03}s`
                                  : undefined,
                              }}
                            >
                              {letter === " " ? "\u00A0" : letter}
                            </span>
                          );
                        })}
                        {"\u00A0"}
                      </span>
                    ))}
                  </p>
                  {slide.html && (
                    <p
                      className={`tutorialPopup__main-text html ${
                        activeIndex === index ? "active" : ""
                      }`}
                    >
                      {slide.html}
                    </p>
                  )}
                  </div>

                </SwiperSlide>
              );
            })}

            <div className="tutorialPopup__btns-wrapper">
              <MainBtn asSecondary onClick={onClose}>
                {closeText[siteLanguage]}
              </MainBtn>
              <MainBtn
                ref={nextButtonRef}
                onClick={() => setTypingSkiped(false)}
                className={`tutorialPopup__nav-btn tutorialPopup__next-btn ${
                  activeIndex === slides.length - 1 ? "hidden" : ""
                }`}
              >
                {nextText[siteLanguage]}
              </MainBtn>
            </div>
          </Swiper>
        </div>
      </div>
    </Popup>
  );
};

export default TutorialPopup;
