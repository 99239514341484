import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TRANSLATIONS } from "assets/translations";
import WalletForm from "components/WalletPage/WalletForm/WalletForm";
import { changeTokenToTon } from "store/actions/profile";
import { formatMilliseconds } from "utils/formatMilliseconds";
import { statsTokenImg, statsTonImg } from "assets/images";

const {
  tokenText,
  tonText,
  tokenToTonText,
  notAvaiableExchangePointText,
  availableInText,
} = TRANSLATIONS.wallet;

const TokenToTonWalletForm = () => {
  const dispatch = useDispatch();
  const token_ton_rate = useSelector((state) => state.profile.token_ton_rate);
  const exchange_rate_timer = useSelector(
    (state) => state.profile.exchange_rate_timer
  );
  const token_to_ton_flag = useSelector(
    (state) => state.profile.token_to_ton_flag
  );
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const tokens = useSelector((state) => state.profile.stats.tokens);
  const max_tokens_to_ton = useSelector(
    (state) => state.profile.max_tokens_to_ton
  );

  const submit = async (value) => {
    await dispatch(changeTokenToTon(value));
  };
  return (
    <WalletForm
      rate={!token_to_ton_flag ? token_ton_rate : 0}
      sendingValue={tokens}
      sendingValueText={tokenText}
      receivingValueText={tonText}
      maxValue={max_tokens_to_ton}
      sendingValueImg={statsTokenImg}
      receivingValueImg={statsTonImg}
      submit={submit}
      title={tokenToTonText}
      receivingValueFixedCount={4}
      notAvaiableText={
        !max_tokens_to_ton || !token_ton_rate
          ? notAvaiableExchangePointText
          : `${availableInText[siteLanguage]} ${formatMilliseconds(
              exchange_rate_timer
            )}`
      }
    />
  );
};

export default TokenToTonWalletForm;
