import { setLanguage } from "store/slices/uiSlice"



export const changeLanguage = (payload) => (dispatch) => {
    dispatch(setLanguage(payload))
    localStorage.setItem("language",payload)
}


