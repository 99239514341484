import { useEffect, useRef } from "react";

export const useCopyRef = (state) => {
  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  return stateRef;
};
