import { TRANSLATIONS } from "assets/translations";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeMatterToToken } from "store/actions/profile";
import WalletForm from "../WalletForm/WalletForm";
import { statsTokenImg } from "assets/images";
import { statsDarkImg } from "assets/images";

const {
  matterToTokenText,
  notEnoughTMText,
  matterText,
  tokenText,
  notAvaiableText,
} = TRANSLATIONS.wallet;

const MatterToTokenWalletForm = () => {
  const dispatch = useDispatch();
  const matterToTonRate = useSelector((state) => state.profile.matterToTonRate);
  const matter = useSelector((state) => state.profile.stats.matter);

  const submit = async (value) => {
    await dispatch(changeMatterToToken(value));
  };
  
  return (
    <WalletForm
      rate={matterToTonRate}
      sendingValue={matter}
      sendingValueText={matterText}
      receivingValueText={tokenText}
      submit={submit}
      title={matterToTokenText}
      notAvaiableText={!matterToTonRate ?  notAvaiableText : notEnoughTMText}
      sendingValueImg={statsDarkImg}
      receivingValueImg={statsTokenImg}
    />
  );
};

export default MatterToTokenWalletForm;
