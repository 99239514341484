import React, { useEffect } from "react";
import { useHarvestPerSec } from "hooks/useHarvestPerSec";
import { useTimer } from "hooks/useTimer";
import { useDispatch, useSelector } from "react-redux";
import {
  increaseCasinoTimer,
  increaseDailyTimer,
  increaseExchangeRateTimer,
  increasePermanentTimer,
  increasePoolTimer,
  increaseSpeedTimer,
  increaseSubscribeTimers,
} from "store/slices/profileSlice";
import { fetchCasinoBonusValue, fetchPoolBonusValue } from "store/actions/profile";

const Timers = ({loading}) => {
    const dispatch = useDispatch();
  const timeSpeedBonus = useSelector(
    (state) => state.profile.timer_speed_bonus
  );
  const endOfDay = useSelector((state) => state.profile.dailylogin.end_of_day);
  const datePermanentBonus = useSelector(
    (state) => state.profile.date_permanent_bonus
  );
  const poolTimer = useSelector((state) => state.profile.poolTimer);
  const casinoTimer = useSelector((state) => state.profile.casinoTimer);
  const exchange_rate_timer = useSelector((state) => state.profile.exchange_rate_timer);
  const subscribe = useSelector((state) => state.profile.subscribe);
  const subscribe_partners = useSelector(
    (state) => state.profile.subscribe_partners
  );

  useHarvestPerSec();
  useTimer(timeSpeedBonus, increaseSpeedTimer);
  useTimer(datePermanentBonus, increasePermanentTimer);
  useTimer(endOfDay, increaseDailyTimer);
  useTimer(poolTimer, increasePoolTimer);
  useTimer(casinoTimer, increaseCasinoTimer);
  useTimer(exchange_rate_timer, increaseExchangeRateTimer);

  const subscribeTimer = [
    ...Object.values(subscribe),
    ...Object.values(subscribe_partners),
  ].find((item) => item?.time_left);

  useTimer(subscribeTimer?.time_left || 0, increaseSubscribeTimers);


  useEffect(() => {
    if (!loading) {

      dispatch(fetchPoolBonusValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, poolTimer === 0]);

  useEffect(() => {
    if (!loading) {
      dispatch(fetchCasinoBonusValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, casinoTimer === 0]);

  return <></>;
};

export default Timers;
