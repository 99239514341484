import { createSlice } from "@reduxjs/toolkit";
import { ads, spots } from "../../constants/adRouter";

const initialState = {
  routes: {
    [spots.collectModalBanner]: {
      desktop: ads.onclickaB6050153,
      mobile: ads.onclickaB6050153,
    },
    [spots.resourcesBonusModalBanner]: {
      desktop: ads.onclickaB6050153,
      mobile: ads.onclickaB6050153,
    },
    [spots.permanentBonusModalBanner]: {
      desktop: ads.onclickaB6050153,
      mobile: ads.onclickaB6050153,
    },
    [spots.buildUpgradeModalBanner]: {
      desktop: ads.onclickaB6050153,
      mobile: ads.onclickaB6050153,
    },
    [spots.taskListBanner1]: {
      desktop: ads.onclickaB6050154,
      mobile: ads.onclickaB6050154,
    },
    [spots.taskListBanner2]: {
      desktop: ads.onclickaB6052313,
      mobile: ads.onclickaB6052313,
    },
    [spots.taskListBanner3]: {
      desktop: ads.onclickaB6052313,
      mobile: ads.onclickaB6052313,
    },
    [spots.hackTerminalBanner]: {
      desktop: ads.onclickaB6056755,
      mobile: ads.onclickaB6056755,
    },
    [spots.miniPuzzleBanner]: {
      desktop: ads.onclickaB6056755,
      mobile: ads.onclickaB6056755,
    },
    [spots.collectModalVideo]: {
      desktop: ads.adsgramV6013,
      mobile: ads.adsgramV6013,
    },
    [spots.resourcesBonusModalVideo]: {
      desktop: ads.adsgramV6008,
      mobile: ads.adsgramV6008,
    },
    [spots.permanentBonusModalVideo]: {
      desktop: ads.mybidV2007067,
      mobile: ads.mybidV2007067,
    },
    [spots.speedBonusVideo]: {
      desktop: ads.adsgramV6011,
      mobile: ads.adsgramV6011,
    },
    [spots.buildUpgradeModalVideo]: {
      desktop: ads.adsgramV6018,
      mobile: ads.adsgramV6018,
    },
    [spots.hackTerminalVideo]: {
      desktop: ads.onclickaV6056936,
      mobile: ads.onclickaV6056936,
    },
    [spots.miniPuzzleVideo]: {
      desktop: ads.adsgramI7928,
      mobile: ads.adsgramI7928,
    },
  },
};

const adRouterSlice = createSlice({
  name: "adRouter",
  initialState,
  reducers: {
    updateAdRoute(state, { payload }) {
      state.routes = { ...state.routes, ...payload };
    },
  },
});

export const { updateAdRoute } = adRouterSlice.actions;

export default adRouterSlice.reducer;
