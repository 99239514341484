import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TRANSLATIONS } from "assets/translations";
import { withdrawTokens, withdrawTon } from "store/actions/profile";
import MainInput from "components/layout/MainInput/MainInput";
import { formatNumber } from "utils/formatNumber";
import Svg from "components/layout/Svg/Svg";
import { setAllBgIcon } from "assets/svg";
import MainBtn from "components/layout/MainBtn/MainBtn";
import { useTooltip } from "hooks/usetooltip";
import { useNavigate } from "react-router-dom";
import { mainPagePath } from "router/path";
import Tooltip from "components/layout/Tooltip/Tooltip";

import "./WitdrawForm.scss";
import Select from "components/layout/Select/Select";

const {
  tonText,
  tokenText,
  addressText,
  withdrawText,
  setAllText,
  submitText,
  trandferCompletedText,
  trandferFailedText,
  walletTypeText,
  feeText,
} = TRANSLATIONS.wallet;

const typeOptions = [
  {
    value: "ton",
    label: tonText,
  },
  {
    value: "token",
    label: tokenText,
  },
];

const WitdrawForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const ton = useSelector((state) => state.profile.ton);
  const tokens = useSelector((state) => state.profile.stats.tokens);
  const ton_comission = useSelector((state) => state.profile.ton_comission);
  const tokens_comission = useSelector(
    (state) => state.profile.tokens_comission
  );
  const [value, setValue] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState(typeOptions[0]);
  const [loading, setLoading] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const { openTooltip, show } = useTooltip();

  const options = {
    ton: {
      commision: ton_comission,
      value: ton,
      submit: async () => dispatch(withdrawTon(value - ton_comission, address)),
    },
    token: {
      commision: tokens_comission,
      value: tokens,
      submit: async () =>
        dispatch(withdrawTokens(value - tokens_comission, address)),
    },
  };
  const commisionValue = options[type.value].commision;

  const maxValue = options[type.value].value;

  const submit = options[type.value].submit;

  const isValueInvalid =
    +value > maxValue || (value && value - commisionValue < 0);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await submit();
      setTooltipText(trandferCompletedText[siteLanguage]);
      openTooltip();

      setTimeout(() => {
        navigate(mainPagePath);
      }, 2000);
    } catch (error) {
      setTooltipText(trandferFailedText[siteLanguage]);
      openTooltip();
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container">
      <div className="witdraw-form">
        <h3 className="witdraw-form__title">{withdrawText[siteLanguage]}</h3>

        <>
          <Select
            value={type}
            onChange={setType}
            placeholder={walletTypeText[siteLanguage]}
            options={typeOptions}
            className={"witdraw-form__select"}
          />
          <MainInput
            type="number"
            value={value && +(+value).toFixed(5)}
            disabled={loading}
            onChange={(e) => setValue(e.target.value)}
            placeholder={`0 ${type.label[siteLanguage]}`}
            invalid={isValueInvalid}
          />
          <div className="witdraw-form__set-all-wrapper">
            <button
              onClick={() => setValue(maxValue)}
              className="witdraw-form__see-all-btn"
            >
              <span>
                {setAllText[siteLanguage]} ({formatNumber(maxValue, 3)})
              </span>
              <Svg id={setAllBgIcon} className="witdraw-form__see-all-btn-bg" />
            </button>
            {!!+value && (
              <p
                className={`witdraw-form__commission-text ${
                  +value && +value - commisionValue < 0 ? "invalid" : ""
                }`}
              >
                {feeText[siteLanguage]
                  .replace("COMMISSION", commisionValue)
                  .replace("WITHDRAWAL", +value - commisionValue)}
              </p>
            )}{" "}
          </div>
          <MainInput
            value={address}
            placeholder={addressText[siteLanguage]}
            type="text"
            disabled={loading}
            onChange={(e) => setAddress(e.target.value)}
          />
          <MainBtn
            onClick={onSubmit}
            disabled={!+value || isValueInvalid || loading || !address}
            className="witdraw-form__submit-btn"
          >
            {submitText[siteLanguage]}
          </MainBtn>
        </>
      </div>
      <Tooltip show={show} text={tooltipText} />
    </div>
  );
};

export default WitdrawForm;
