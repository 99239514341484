import React, { useState } from "react";

import Svg from "../Svg/Svg";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

import "./Select.scss";
import { inputBgIcon } from "assets/svg";
import { useSelector } from "react-redux";
import { selectArrowIcon } from '../../../assets/svg';

const Select = ({
  value,
  onChange,
  placeholder,
  isInvalid,
  options,
  className,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const isMultiselect = Array.isArray(value);

  const onClickOption = (item) => {
    if (!isMultiselect) setDropdownOpened(false);
    else {
      const curItem = value.find((val) => item.value === val);
      if (curItem) return;
    }
    onChange(isMultiselect ? [...value, item.value] : item);
  };

  return (
    <div
      className={`select ${dropdownOpened ? "select_opened" : ""} ${
        className || ""
      }`}
    >
      <div
        className={`select__dropdownBtn ${
          isInvalid ? "select__dropdownBtn_invalid" : ""
        }`}
        onClick={() => {
          if (!options.length) return;
          setDropdownOpened((prevState) => !prevState);
        }}
      >
        {!isMultiselect ? (
          <span
            className={`select__dropdownBtnText ${
              !value ? "select__dropdownBtnText_empty" : ""
            }`}
          >
            {value?.label?.[siteLanguage] || placeholder || "Select"}
          </span>
        ) : (
          <div className={"select__valueItems"}>
            {value && value.length ? (
              value.map((val, index) => {
                const item = options.find((opt) => opt.value === val);
                return (
                  <div className={"select__valueItem"} key={index}>
                    <span className={"select__valueItemTxt"}>
                      {item?.label?.[siteLanguage] || ""}
                    </span>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(value.filter((val) => val !== item.value));
                      }}
                      className={"select__valueItemCrossBtn"}
                    >
                      <Svg
                        id="cross"
                        className={"select__valueItemCrossIcon"}
                      />
                    </button>
                  </div>
                );
              })
            ) : (
              <span
                className={`select__dropdownBtnText ${
                  !value.length ? "select__dropdownBtnText_empty" : ""
                }`}
              >
                Select
              </span>
            )}
          </div>
        )}
        {!!options.length && (
          <Svg
            id={selectArrowIcon}
            className={`select__dropdownBtnArrow ${
              dropdownOpened ? "select__dropdownBtnArrow_active" : ""
            }`}
          ></Svg>
        )}
        <Svg id={inputBgIcon} className="select__dropdownBtnBg" />
      </div>
      <TransitionProvider
        className={"select__dropdownContent"}
        style={TransitionStyleTypes.maxHeight}
        inProp={dropdownOpened}
        maxheight={"200px"}
      >
        {options.map((item, index) => (
          <button
            type="button"
            onClick={() => onClickOption(item)}
            key={item.value}
            className={"select__dropdownContentItem"}
          >
            <span className={"select__dropdownContentItemTxt"}>
              {item.label[siteLanguage]}
            </span>
            {isMultiselect &&
              !!(value && value.find((val) => item.value === val)) && (
                <Svg
                  id="check"
                  className={"select__dropdownContentCheckicon"}
                />
              )}
          </button>
        ))}
      </TransitionProvider>
    </div>
  );
};

export default Select;
