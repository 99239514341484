import React, { useEffect, useRef, useState } from "react";

import "./ImagePuzzleMain.scss";
import ImagePuzzleHeader from "../ImagePuzzleHeader/ImagePuzzleHeader";
import ImagePuzzleGame from "../ImagePuzzleGame/ImagePuzzleGame";
import ImagePuzzleAwards from "../ImagePuzzleAwards/ImagePuzzleAwards";
import { TIMER_LIMIT_SEC } from "constants/imagePuzzle";
import { fecthPuzzleRandomImage } from "store/actions/puzzle";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import { useDispatch, useSelector } from "react-redux";
import { getCurAd } from "utils/adRouter";
import { spots } from "constants/adRouter";
import { fetchAd } from "store/actions/adRouter";
import { useLazy } from "../../../useLazy";

const onClickSpotId = 6056755;

const ImagePuzzleMain = () => {
  const dispatch = useDispatch();
  const tgId = useSelector((state) => state.profile.tgData.tgId);
  const [isRewardsActive, setIsRewardsActive] = useState(false);
  const [timer, setTimer] = useState(TIMER_LIMIT_SEC);
  const [collectedPeacesCount, setCollectedPeacesCount] = useState(0);
  const intervalRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState("");
  const routes = useSelector((state) => state.adRouter.routes);
  const { isInView, ref, setIsInView } = useLazy();

  const { id: bannerId, curAd: curBanerAd } = getCurAd(
    routes,
    spots.miniPuzzleBanner
  );

  const fetchImage = async () => {
    setLoading(true);
    const { image } = await fecthPuzzleRandomImage();
    setImage(image);
    setLoading(false);
  };

  useEffect(() => {
    if (tgId) {
      fetchImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgId]);

  useEffect(() => {
    if (isInView && tgId) {

      dispatch(fetchAd(spots.miniPuzzleBanner));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView, tgId]);

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setTimer((prevState) => prevState - 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(intervalRef.current);
  };
  const restartTimer = () => {
    stopTimer();
    setTimer(TIMER_LIMIT_SEC);
  };

  return (
    <div className="image-puzzle-main">
      <TransitionProvider
        inProp={loading}
        style={TransitionStyleTypes.opacity}
        className="image-puzzle-main__loader"
      ></TransitionProvider>
      <ImagePuzzleHeader
        timer={timer}
        isRewardsActive={isRewardsActive}
        setIsRewardsActive={setIsRewardsActive}
        collectedPeacesCount={collectedPeacesCount}
      />
      {!isRewardsActive ? (
        <ImagePuzzleGame
          startTimer={startTimer}
          restartTimer={restartTimer}
          stopTimer={stopTimer}
          fetchImage={fetchImage}
          timerFinished={!timer}
          image={image}
          initBanner={() => {
            curBanerAd?.init(onClickSpotId);
            if (
              document.body.getBoundingClientRect().height >
              window.innerHeight + 100
            ) {
              setIsInView(false);
            } else {
              dispatch(fetchAd(spots.miniPuzzleBanner));
            }
          }}
          setCollectedPeacesCount={setCollectedPeacesCount}
        />
      ) : (
        <ImagePuzzleAwards setIsRewardsActive={setIsRewardsActive} />
      )}

      <div className="image-puzzle-main__banner" ref={ref}>
        {curBanerAd.render(bannerId)}
      </div>
    </div>
  );
};

export default ImagePuzzleMain;
