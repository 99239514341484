import { useDispatch } from "react-redux";
import { getTaddy } from "store/slices/tasksSlice";
import { Taddy } from "taddy-sdk";

export const useTaddy = () => {
  const dispatch = useDispatch();
  const taddy = new Taddy(process.env.REACT_APP_TADDY_API_KEY);

  const init = () => {
    taddy.ready();

    dispatch(getTaddy(taddy));
    // taddy
    //   .tasks({ limit: 4, autoImpressions: true })
    //   .then((tasks) => {
    //     // description
    //     // id
    //     // image
    //     // link
    //     // title
    //     dispatch(getTaddyTasks(tasks));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  return { init };
};
