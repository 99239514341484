import React, { useState } from "react";
import "./Header.scss";

import { useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import {
  statsDarkImg,
  statsResImg,
  statsTokenImg,
  statsTonImg,
} from "assets/images";
import {
  hackTerminalPagePath,
  imagePuzzlePagePath,
  mainPagePath,
  walletPagePath,
} from "router/path";
import {
  avatarBgIcon,
  backIcon,
  headerBgIcon,
  resourcesIcon,
  settingsIcon,
  userIcon,
} from "assets/svg";
import Svg from "../layout/Svg/Svg";
import { formatNumber } from "utils/formatNumber";
import SettingsPopup from "components/SettingsPopup/SettingsPopup";
import { TRANSLATIONS } from "assets/translations";
import { useTooltip } from "hooks/usetooltip";
import Tooltip from "components/layout/Tooltip/Tooltip";

const inDevelopmentText = TRANSLATIONS.inDevelopmentText;

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const avatar = useSelector((state) => state.profile.tgData.avatar);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const { show, openTooltip } = useTooltip();

  const resources = useSelector((state) => state.profile.stats.resources);
  const matter = useSelector((state) => state.profile.stats.matter);
  const tokens = useSelector((state) => state.profile.stats.tokens);
  const ton = useSelector((state) => state.profile.ton);

  const showLocation = location.pathname !== mainPagePath;
  const isHidden = [hackTerminalPagePath, imagePuzzlePagePath].includes(
    location.pathname
  );

  const isInWalletPage = location.pathname === walletPagePath;

  const stats = [
    { value: resources, icon: statsResImg },
    { value: matter, icon: statsDarkImg, toFixed: 3 },
    { value: tokens, icon: statsTokenImg, toFixed: 3 },
  ];

  if (location.pathname === walletPagePath) {
    stats.push({ value: ton, icon: statsTonImg, toFixed: 4 });
    stats.shift();
  }

  if (isHidden) return null;
  return (
    <header className="header">
      <div className="container">
        <div className="header-wrapper f-center-jcsb">
          <button
            onClick={!isInWalletPage ? openTooltip : () => navigate(-1)}
            className="header-link f-center-center"
          >
            <Svg id={isInWalletPage ? backIcon : userIcon} />
          </button>
          <button onClick={openTooltip} className="header-user">
            <div className="header-user__avatar">
              {avatar && <img className="cover-image" src={avatar} alt="" />}
            </div>
            <div className="header-user__bg">
              <Svg id={avatarBgIcon} />
            </div>
          </button>
          <button
            onClick={() => setSettingsOpened(true)}
            className="header-link f-center-center"
          >
            <Svg id={settingsIcon} />
          </button>
          <div className="header-wrapper__bg">
            <Svg id={headerBgIcon} />
          </div>
        </div>
        {showLocation && (
          <div
            className="header-resources"
            onClick={() => navigate(walletPagePath)}
          >
            <ul className="header-resources-list f-center-center">
              {stats.map(({ value, icon, toFixed }, index) => (
                <li
                  key={index}
                  className="header-resources-list__item f-center"
                >
                  <div className="header-resources-list__icon">
                    <img className="cover-image" src={icon} alt="" />
                  </div>
                  <p className="header-resources-list__text">
                    {toFixed ? (+value).toFixed(toFixed) : formatNumber(value)}
                  </p>
                </li>
              ))}
            </ul>
            <div className="header-resources__bg">
              <Svg id={resourcesIcon} />
            </div>
          </div>
        )}
      </div>
      <SettingsPopup
        activePopup={settingsOpened}
        onClose={() => setSettingsOpened(false)}
      />
      <Tooltip show={show} text={inDevelopmentText[siteLanguage]} />
    </header>
  );
};

export default Header;
