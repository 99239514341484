import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taddy: null,
  bonus: {
    bonus_type: "matter",
    bonus_size: 0,
  },
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    getTaddy(state, { payload }) {
      state.taddy = payload;
    },
    getTaddyBonus(state, { payload }) {
      state.bonus = payload;
    },
  },
});

export const { getTaddy, getTaddyBonus } = tasksSlice.actions;

export default tasksSlice.reducer;
