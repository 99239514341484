import React from "react";
import Svg from "components/layout/Svg/Svg";
import { buildsModeSwithBgIcon, buildsTabBarBgIcon } from "assets/svg";
import "./BuildsTabBar.scss";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";
// import { useTooltip } from "hooks/usetooltip";
// import Tooltip from "components/layout/Tooltip/Tooltip";

const {
  buildingsText,
  cityImprovementsText,
  specialBuildingsText,
  modeChangeText,
  maxText,
} = TRANSLATIONS.buildings;
// const inDevelopmentText = TRANSLATIONS.inDevelopmentText;
const tabs = [
  {
    name: buildingsText,
    key: "building",
  },
  {
    name: cityImprovementsText,
    key: "city_upgrade",
  },
  {
    name: specialBuildingsText,
    key: "special_building",
  },
];

const BuildsTabBar = ({
  activeTab,
  setActiveTab,
  changeLevelMode,
  curLevelMode,
}) => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  // const { show, openTooltip } = useTooltip();

  return (
    <>
      <div className="builds-tabBar f-center-jcsb">
        <div className="builds-tabBar__wrapper f-center">
          {tabs.map((item, index) => (
            <div
              key={index}
              className={`builds-tabBar__item f-center-center ${
                activeTab === item.key && "active"
              }`}
              onClick={() => {
                setActiveTab(item.key);
              }}
            >
              {item.name[siteLanguage]}
            </div>
          ))}
        </div>
        <button
          onClick={changeLevelMode}
          className="builds-tabBar__change f-center-center"
        >
          <p className="builds-tabBar__change-text">
            {modeChangeText[siteLanguage]}
            <br />

            {curLevelMode === "max"
              ? maxText[siteLanguage]
              : `+${curLevelMode || 1}`}
          </p>
          <div className="builds-tabBar__change-bg">
            <Svg id={buildsModeSwithBgIcon} />
          </div>
        </button>
        <div className="builds-tabBar__bg">
          <Svg id={buildsTabBarBgIcon} />
        </div>
      </div>
      {/* <Tooltip show={show} text={inDevelopmentText[siteLanguage]} /> */}
    </>
  );
};

export default BuildsTabBar;
