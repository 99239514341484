import {
  resourcesPopupBottomIcon,
  resourcesPopupLeftFigureIcon,
  resourcesPopupMoreIcon,
  resourcesPopupRightFigureIcon,
  resourcesPopupValueBgIcon,
} from "assets/svg";
import { TRANSLATIONS } from "assets/translations";
import MainBtn from "components/layout/MainBtn/MainBtn";
import Popup from "components/layout/Popup/Popup";
import Svg from "components/layout/Svg/Svg";
import { statsImages } from "constants/stats";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./ResetModal.scss";
import { formatNumber } from "../../../utils/formatNumber";
import { fetchResetBonusValue, resetProgress } from "store/actions/profile";

const { availableForCollectionText, collectText } = TRANSLATIONS.resourcePopup;

const ResetModal = ({ onClose, activePopup }) => {
  const dispatch = useDispatch();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const resetEnable = useSelector((state) => state.profile.resetEnable);
  const resetBonusValue = useSelector((state) => state.profile.resetBonusValue);
  const [loading, setLoading] = useState(false);

  const onReset = async () => {
    setLoading(true);
    await dispatch(resetProgress());
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    if (resetEnable) {
      dispatch(fetchResetBonusValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetEnable]);

  return (
    <Popup onClose={onClose} activePopup={activePopup}>
      <div className="reset-modal">
        <TransitionProvider
          style={TransitionStyleTypes.opacity}
          inProp={loading}
          className="reset-modal__loader"
        ></TransitionProvider>
        <h2 className="reset-modal__title">
          {availableForCollectionText[siteLanguage]}
        </h2>
        <div className="reset-modal__wrap f-jcsb">
          <div className="reset-modal__figure">
            <Svg id={resourcesPopupLeftFigureIcon} />
          </div>
          <div className="reset-modal__value f-center-center">
            <div className={"reset-modal__value-content f-center"}>
              <>
                <img src={statsImages.matter} alt="" />
                {formatNumber(resetBonusValue)}
              </>
            </div>

            <div className="reset-modal__value-bg">
              <Svg id={resourcesPopupValueBgIcon} />
            </div>
          </div>
          <div className="reset-modal__figure">
            <Svg id={resourcesPopupRightFigureIcon} />
          </div>
        </div>
        <div className="reset-modal__more">
          <Svg id={resourcesPopupMoreIcon} />
        </div>
        <div className="reset-modal__nav f-center-jcsb">
          <MainBtn onClick={onReset} asSecondary>
            {collectText[siteLanguage]}
          </MainBtn>
        </div>

        <div className="reset-modal__bottom">
          <Svg id={resourcesPopupBottomIcon} />
        </div>
      </div>
    </Popup>
  );
};

export default ResetModal;
