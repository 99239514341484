import { baseUrl } from "store/tools/fetchTools";

const WALLGRAM_BASE_URL = `${baseUrl}tasks/wallgram/`;

export default class Wallgram {
  constructor(tgId, language, ip) {
    this.tgId = tgId;
    this.language = language;
    this.ip = ip;
    this.userAgent = navigator.userAgent;
  }

  async fetchRequest(url, method = "GET", body) {
    const urlParams = new URLSearchParams({
      language: this.language,
      telegram_id: this.tgId,
      user_agent: this.userAgent,
      ip: this.ip,
    });

    const res = await fetch(
      `${WALLGRAM_BASE_URL}${url}${
        url.includes("?") ? "&" : "?"
      }${urlParams.toString()}`,
      {
        method,
        body,
      }
    );

    const data = await res.json();
    if (!res.ok) {
      // eslint-disable-next-line no-throw-literal
      throw { message: data, status: res.status };
    }

    return data;
  }

  async fetchTasks(isPremium = false) {
    const response = await this.fetchRequest(`info/?is_premium=${isPremium}`);

    return response?.value?.tasks;
  }
  async startTask(taskId, campaignId) {
    const response = this.fetchRequest(
      `start/?task_id=${taskId}&campaign_id=${campaignId}`,
      "POST"
    );

    return response;
  }

  async completeTask(taskId) {
    const response = this.fetchRequest(`complete/?task_id=${taskId}`, "POST");
    return response;
  }
}
