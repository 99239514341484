import React, { useEffect, useState } from "react";
import "./ResourcesPopup.scss";
import Svg from "components/layout/Svg/Svg";
import {
  resourcesPopupBottomIcon,
  resourcesPopupLeftFigureIcon,
  resourcesPopupMoreIcon,
  resourcesPopupRightFigureIcon,
  resourcesPopupValueBgIcon,
} from "assets/svg";
import { useLocation, useNavigate } from "react-router-dom";
import { statsImages } from "constants/stats";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import { TRANSLATIONS } from "assets/translations";
import { useDispatch, useSelector } from "react-redux";
import Popup from "components/layout/Popup/Popup";
import MainBtn from "components/layout/MainBtn/MainBtn";
import { useTooltip } from "hooks/usetooltip";
import Tooltip from "components/layout/Tooltip/Tooltip";
import { getCurAd } from "utils/adRouter";
import { useGlobalAdController } from "hooks/useGlobalAdController";
import { fetchAd } from "store/actions/adRouter";

const {
  orText,
  viewAdText,
  availableForCollectionText,
  collectText,
  adsUnavailableText,
  fewText,
} = TRANSLATIONS.resourcePopup;

const ResourcesPopup = ({
  activePopup,
  onClosePopup,
  incomes,
  adIncomes,
  primaryBtnClass,
  title,
  primaryBtnTxt,
  primaryBtnStats,
  loading,
  onClickPrimaryBtn,
  adbtnClass,
  adBtnTxt,
  onClickAdBtn,
  bannerSpotId,
  videoSpotId,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { show, openTooltip } = useTooltip();
  const routes = useSelector((state) => state.adRouter.routes);
  const [curBanner, setCurBanner] = useState(getCurAd(routes, bannerSpotId));
  const [tooltipText, setTooltipText] = useState("");

  const onShowAd = useGlobalAdController(
    videoSpotId,
    () => {
      dispatch(fetchAd(videoSpotId));
      onClickAdBtn();
    },
    (err) => {
      const timeText = err?.timer
        ? Math.round(err?.timer / 1000)
        : fewText[siteLanguage];
      setTooltipText(
        <>
          {adsUnavailableText[siteLanguage][0]} <br />
          {adsUnavailableText[siteLanguage][1].replace("TIME", timeText)}
        </>
      );
      openTooltip();
    },
    [activePopup === true]
  );
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const bannerId = curBanner?.id;
  const curBanerAd = curBanner?.curAd;

  useEffect(() => {
    if (activePopup) {
      if ("init" in curBanerAd) curBanerAd.init(bannerId);
      setCurBanner(getCurAd(routes, bannerSpotId));
      dispatch(fetchAd(bannerSpotId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePopup]);

  const onClose = () => {
    onClosePopup();

    if (location.search.includes("popup=open")) {
      navigate(location.pathname);
    }
  };

  return (
    <>
      <Popup onClose={onClose} activePopup={activePopup}>
        <div className="resources-popup">
          <TransitionProvider
            style={TransitionStyleTypes.opacity}
            inProp={loading}
            className="resources-popup__loader"
          ></TransitionProvider>
          <h2 className="resources-popup__title">
            {title || availableForCollectionText[siteLanguage]}
          </h2>
          <div className="resources-popup__wrap f-jcsb">
            <div className="resources-popup__figure">
              <Svg id={resourcesPopupLeftFigureIcon} />
            </div>
            <div className="resources-popup__value f-center-center">
              {incomes.map(({ text, type }, index) => (
                <div
                  key={index}
                  className="resources-popup__value-content f-center"
                >
                  {type in statsImages ? (
                    <img src={statsImages[type]} alt="" />
                  ) : (
                    ""
                  )}
                  {text}
                </div>
              ))}
              <div className="resources-popup__value-bg">
                <Svg id={resourcesPopupValueBgIcon} />
              </div>
            </div>
            <div className="resources-popup__figure">
              <Svg id={resourcesPopupRightFigureIcon} />
            </div>
          </div>
          <div className="resources-popup__more">
            <Svg id={resourcesPopupMoreIcon} />
          </div>
          <div className="resources-popup__nav f-center-jcsb">
            <MainBtn
              asSecondary
              className={primaryBtnClass || ""}
              disabled={!onClickPrimaryBtn}
              onClick={() => {
                onClickPrimaryBtn();
              }}
            >
              {primaryBtnTxt || collectText[siteLanguage]}
              <div className="resources-popup__points">
                {primaryBtnStats &&
                  primaryBtnStats.map(({ text, type }, index) => (
                    <div
                      className="resources-popup__points-item f-center"
                      key={index}
                    >
                      {type in statsImages && (
                        <img src={statsImages[type]} alt="" />
                      )}
                      {text}
                    </div>
                  ))}
              </div>
            </MainBtn>
            <p className="resources-popup__and">{orText[siteLanguage]}</p>
            <MainBtn
              onClick={() => {
                // isMobile
                //   ? onShowAdsgramAd()
                //   : showSonarAd
                //   ? onShowSonarAd()
                //   : onShowOnClickaAd();
                onShowAd();
              }}
              className={adbtnClass || ""}
            >
              {adBtnTxt || <>{viewAdText[siteLanguage]}</>}
              <div className="resources-popup__points">
                {adIncomes &&
                  adIncomes.map(({ text, type }, index) => (
                    <div
                      className="resources-popup__points-item f-center"
                      key={index}
                    >
                      {type in statsImages && (
                        <img src={statsImages[type]} alt="" />
                      )}
                      {text}
                    </div>
                  ))}
              </div>
            </MainBtn>
          </div>

          <div className="resources-popup__inner">
            <div className="resources-popup__banner">
              {curBanerAd?.render(bannerId)}
            </div>
          </div>
          <div className="resources-popup__bottom">
            <Svg id={resourcesPopupBottomIcon} />
          </div>
        </div>
      </Popup>
      <Tooltip show={show} text={tooltipText} />
    </>
  );
};

export default ResourcesPopup;
