import React from "react";
import MainStats from "../MainStats/MainStats";
import MainMineBlock from "../MainMineBlock/MainMineBlock";
import TutorialPopup from "components/TutorialPopup/TutorialPopup";
import {
  onboardingCollectEngImg,
  onboardingCollectRusImg,
  onboardingCurrenciesImg,
  onboardingExtractionTimeEngImg,
  onboardingExtractionTimeRusImg,
  onboardingMatterImg,
  onboardingMenuImg,
  onboardingPermantBonusEngImg,
  onboardingPermantBonusRusImg,
  onboardingResetProgressEngImg,
  onboardingResetProgressRusImg,
  onboardingResImg,
  onboardingResourcesMinedEngImg,
  onboardingResourcesMinedRusImg,
  onboardingResurceBonusEngImg,
  onboardingResurceBonusRusImg,
  onboardingSpeedBonusEngImg,
  onboardingSpeedBonusRusImg,
  onboardingTokenImg,
} from "assets/images";
import { useSelector } from "react-redux";
import { LANGUAGES } from "constants/languages";
import { lsProps } from "constants/lsProps";
import { useTutorial } from "hooks/useTutorial";
import { TRANSLATIONS } from "assets/translations";
import { useLocation } from "react-router-dom";
import { faqLinks } from "constants/faqLinks";
import { useTelegram } from "hooks/useTelegram";

const {
  startText,
  startHtmlText,
  menuText,
  statsText,
  resourceText,
  matterText,
  tokenText,
  mostUsedStatText,
  extractiontimeText,
  resourcesMinedText,
  collectText,
  mlmText,
  permanentBonusText,
  speedBonusText,
  resourceBonusText,
  resetProgressText,
} = TRANSLATIONS.home.tutorial;

const tutorialSlides = (id, siteLanguage,onOpenLink) => {
  const translatedImages = {
    extractionTimer: {
      [LANGUAGES.eng]: onboardingExtractionTimeEngImg,
      [LANGUAGES.rus]: onboardingExtractionTimeRusImg,
    },
    resourcesMined: {
      [LANGUAGES.eng]: onboardingResourcesMinedEngImg,
      [LANGUAGES.rus]: onboardingResourcesMinedRusImg,
    },
    collect: {
      [LANGUAGES.eng]: onboardingCollectEngImg,
      [LANGUAGES.rus]: onboardingCollectRusImg,
    },
    permanentBonus: {
      [LANGUAGES.eng]: onboardingPermantBonusEngImg,
      [LANGUAGES.rus]: onboardingPermantBonusRusImg,
    },
    speedBonus: {
      [LANGUAGES.eng]: onboardingSpeedBonusEngImg,
      [LANGUAGES.rus]: onboardingSpeedBonusRusImg,
    },
    resourcesbonus: {
      [LANGUAGES.eng]: onboardingResurceBonusEngImg,
      [LANGUAGES.rus]: onboardingResurceBonusRusImg,
    },
    resetProgress: {
      [LANGUAGES.eng]: onboardingResetProgressEngImg,
      [LANGUAGES.rus]: onboardingResetProgressRusImg,
    },
  };

  return [
    {
      text: startText[siteLanguage].replace("ID", id),
      html: (
        <>
          {startHtmlText[siteLanguage]
            .split(/<FAQ_LINK>|<\/FAQ_LINK>/)
            .map((part, index) =>
              index === 1 ? (
                <button
                  key={index}
                 onClick={() => onOpenLink(faqLinks[siteLanguage])}
                >
                  {part}
                </button>
              ) : (
                part
              )
            )}
        </>
      ),
    },
    {
      text: menuText[siteLanguage],
      img: onboardingMenuImg,
    },
    {
      text: statsText[siteLanguage],
      img: onboardingCurrenciesImg,
    },
    {
      text: resourceText[siteLanguage],
      img: onboardingResImg,
    },
    {
      text: matterText[siteLanguage],
      img: onboardingMatterImg,
    },
    {
      text: tokenText[siteLanguage],
      img: onboardingTokenImg,
    },
    {
      text: mostUsedStatText[siteLanguage],
    },
    {
      text: extractiontimeText[siteLanguage],
      img: translatedImages.extractionTimer[siteLanguage],
    },
    {
      text: resourcesMinedText[siteLanguage],
      img: translatedImages.resourcesMined[siteLanguage],
    },
    {
      text: collectText[siteLanguage],
      img: translatedImages.collect[siteLanguage],
    },
    {
      text: mlmText[siteLanguage],
    },
    {
      text: permanentBonusText[siteLanguage],
      img: translatedImages.permanentBonus[siteLanguage],
    },
    {
      text: speedBonusText[siteLanguage],
      img: translatedImages.speedBonus[siteLanguage],
    },
    {
      text: resourceBonusText[siteLanguage],
      img: translatedImages.resourcesbonus[siteLanguage],
    },
    {
      text: resetProgressText[siteLanguage],
      img: translatedImages.resetProgress[siteLanguage],
    },
  ];
};

const MainWrapper = () => {
  const location = useLocation();
  const tg = useTelegram()
  const tgId = useSelector((state) => state.profile.tgData.tgId);
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const { onClose, showTutorial } = useTutorial(
    lsProps.homeTutorialShowed,
    location
  );


const onOpenLink = (link) => {
  tg.openTelegramLink(link)
}


  return (
    <>
      <section className="container">
        <div className="main-wrapper space-control">
          <MainStats />
          <MainMineBlock />
        </div>
      </section>
      <TutorialPopup
        active={showTutorial}
        onClose={onClose}
        slides={tutorialSlides(tgId, siteLanguage,onOpenLink)}
      />
    </>
  );
};

export default MainWrapper;
