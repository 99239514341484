import React, { useState } from "react";

import "./WalletForm.scss";
import Svg from "components/layout/Svg/Svg";
import { setAllBgIcon } from "assets/svg";
import MainBtn from "components/layout/MainBtn/MainBtn";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";
import { formatNumber } from "utils/formatNumber";
import { useNavigate } from "react-router-dom";
import { useTooltip } from "hooks/usetooltip";
import { mainPagePath } from "router/path";
import Tooltip from "../../layout/Tooltip/Tooltip";
import MainInput from "components/layout/MainInput/MainInput";

const { setAllText, submitText, trandferCompletedText, trandferFailedText } =
  TRANSLATIONS.wallet;

const WalletForm = ({
  rate,
  sendingValue,
  maxValue,
  sendingValueText,
  sendingValueImg,
  receivingValueImg,
  receivingValueText,
  receivingValueFixedCount,
  submit,
  title,
  notAvaiableText,
}) => {
  const navigate = useNavigate();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const { openTooltip, show } = useTooltip();

  const resultTokens = +value && +value * rate;

  const max =
    maxValue !== undefined && maxValue < sendingValue ? maxValue : sendingValue;

  const isValueInvalid = +value > max || value < 0;

  const onSubmit = async () => {
    try {
      setLoading(true);
      await submit(value);
      setTooltipText(trandferCompletedText[siteLanguage]);
      openTooltip();

      setTimeout(() => {
        navigate(mainPagePath);
      }, 2000);
    } catch (error) {
      setTooltipText(trandferFailedText[siteLanguage]);
      openTooltip();
    } finally {
      setLoading(false);
    }
  };

  const disabled = !rate || !max;

  return (
    <div className="container">
      <div className="wallet-form">
        <h3 className="wallet-form__title">
          {disabled
            ? typeof notAvaiableText === "string"
              ? notAvaiableText
              : notAvaiableText[siteLanguage]
            : title[siteLanguage]}
        </h3>
        {!disabled && (
          <>
            <div className="wallet-form__cource">
              <span className="wallet-form__cource-text">{1}</span>
              <img
                src={sendingValueImg}
                alt="Dark Matter"
                className="wallet-form__cource-img"
              />
              <span className="wallet-form__cource-text">= {rate}</span>
              <img
                src={receivingValueImg}
                alt="Token"
                className="wallet-form__cource-img"
              />
            </div>
            <MainInput
              type="number"
              value={value && +(+value).toFixed(5)}
              disabled={loading}
              onChange={(e) => setValue(e.target.value)}
              placeholder={`0 ${sendingValueText[siteLanguage]}`}
              invalid={isValueInvalid}
            />
            <button
              onClick={() => setValue(max)}
              className="wallet-form__see-all-btn"
            >
              <span>
                {setAllText[siteLanguage]} ({formatNumber(max)})
              </span>
              <Svg id={setAllBgIcon} className="wallet-form__see-all-btn-bg" />
            </button>
            <MainInput
              value={`${+resultTokens.toFixed(receivingValueFixedCount || 3)} ${
                receivingValueText[siteLanguage]
              }`}
              placeholder={`0 ${receivingValueText[siteLanguage]}`}
              type="text"
              disabled
            />
            <MainBtn
              onClick={onSubmit}
              disabled={!+value || isValueInvalid || loading}
              className="wallet-form__submit-btn"
            >
              {submitText[siteLanguage]}
            </MainBtn>
          </>
        )}
      </div>
      <Tooltip show={show} text={tooltipText} />
    </div>
  );
};

export default WalletForm;
