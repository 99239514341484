export function getLSItem(key, clb) {
  if (
    window.Telegram?.WebApp?.CloudStorage &&
    window.Telegram?.WebApp?.initDataUnsafe?.user
  )
    window.Telegram.WebApp.CloudStorage.getItem(key, clb);
}

export function getLSItems(keys, clb) {
  if (
    window.Telegram?.WebApp?.CloudStorage &&
    window.Telegram?.WebApp?.initDataUnsafe?.user
  )
    window.Telegram.WebApp.CloudStorage.getItems(keys, clb);
}

export function removeLSItems(keys, clb) {
  if (
    window.Telegram?.WebApp?.CloudStorage &&
    window.Telegram?.WebApp?.initDataUnsafe?.user
  )
    window.Telegram.WebApp.CloudStorage.removeItems(keys, clb);
}

export const setLSItem = (key, data) => {
  if (
    window.Telegram?.WebApp?.CloudStorage &&
    window.Telegram?.WebApp?.initDataUnsafe?.user
  ) {
    window.Telegram.WebApp.CloudStorage.setItem(
      key,
      typeof data === "string" ? data : JSON.stringify(data)
    );
  }
};
