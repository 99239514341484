import React, { useState } from "react";
import "./AppContent.scss";
import { useTelegram } from "../../hooks/useTelegram";
import { useEffect } from "react";
import {
  fetchMatterToTokenRate,
  fetchWallgramTasks,
  getData,
} from "store/actions/profile";
import { useDispatch } from "react-redux";

import AppRouter from "router/AppRouter";
import Header from "components/Header/Header";
import MobileNav from "components/MobileNav/MobileNav";
import AppLoader from "components/AppLoader/AppLoader";
import ErrorDesk from "components/ErrorDesk/ErrorDesk";
import { useImageLoader } from "hooks/useImageLoader";
import { LANGUAGES } from "constants/languages";
import { setLanguage } from "store/slices/uiSlice";
import { TRANSLATIONS } from "assets/translations";
import Timers from "components/Timers/Timers";
import eruda from "eruda";
import { refUser, fetchIpAddress } from "../../store/actions/profile";
import { useTaddy } from "hooks/useTaddy";

const {
  gettingDataText,
  addBlockedText,
  longLoadingText,
  desktopAccessText,
  telegramAccessText,
} = TRANSLATIONS.errorDesk;

const errorTexts = {
  adBlocked: addBlockedText,
  gettingData: gettingDataText,
  longLoading: longLoadingText,
  desktopAccess: desktopAccessText,
  telegramAccess: telegramAccessText,
};

const AppContent = () => {
  const [loading, setLoading] = useState(true);
  const tg = useTelegram();
  const dispatch = useDispatch();
  const [errorDeskShowing, setErrorDeskShowing] = useState(false);
  const [error, setError] = useState(""); // keys of errorTexts
  const imageLoading = useImageLoader();
  const taddy = useTaddy();

  // const checkIsBlocked = () => {
  //   // check if adsBlocked

  //   const script = document.createElement("script");

  //   script.type = "text/javascript";
  //   script.src =
  //     "//www.highperformanceformat.com/b8cf70b3ea2d5849d9d0a40c0c9341c7/invoke.js";
  //   script.onerror = (err) => {
  //     setError("adBlocked");
  //     setErrorDeskShowing(true);
  //   };
  //   script.onload = () => {
  //     setErrorDeskShowing(false);
  //     if (document.querySelector("#script-wrapper"))
  //       document.querySelector("#script-wrapper").innerHTML = "";
  //   };

  //   if (document.querySelector("#script-wrapper"))
  //     document.querySelector("#script-wrapper").appendChild(script);
  // };

  useEffect(() => {
    // eruda.init();
    taddy.init();

    if (process.env.NODE_ENV !== "development") {
      document.onkeydown = function (e) {
        if (e.keyCode === 123) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === "I".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === "J".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) {
          return false;
        }
      };
    }

    tg.ready();

    const getLanguage = (language_code) => {
      const lang = localStorage.getItem("language");

      if (lang && lang in LANGUAGES) return lang;
      else if (language_code) {
        if (language_code.includes("ru")) {
          return LANGUAGES.rus;
        }
      }

      return LANGUAGES.eng;
    };
    const fetchData = async (id = 1624247936, lang, avatar, isPremium) => {
      const langauge = getLanguage(lang);
      try {
        if (tg?.initDataUnsafe?.start_param) {
          await refUser(id, langauge, tg?.initDataUnsafe?.start_param);
        }
        await dispatch(
          getData({
            tgId: id,
            avatar: avatar || null,
            siteLanguage: langauge,
            isPremium,
          })
        );
        await dispatch(fetchIpAddress());
        await dispatch(fetchWallgramTasks());
        dispatch(fetchMatterToTokenRate(id, langauge));
      } catch (error) {
        setError(
          error.message === "Request timed out" ? "longLoading" : "gettingData"
        );
        setErrorDeskShowing(true);
      } finally {
        setLoading(false);
      }
    };

    if (tg) {
      if (process.env.NODE_ENV === "development") {
        fetchData();
      }

      if (tg?.initDataUnsafe?.user) {
        const { id, photo_url, language_code, is_premium } =
          tg.initDataUnsafe?.user;

        const lang = getLanguage(language_code);
        dispatch(setLanguage(lang));
        fetchData(id, lang, photo_url, is_premium);
      } else {
        if (process.env.NODE_ENV !== "development") {
          setError("telegramAccess");
          setErrorDeskShowing(true);
          setLoading(false);
        }
        const lang = getLanguage();
        dispatch(setLanguage(lang));
      }
    }
    // checkIsBlocked();

    // get language from local storage

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="appContent">
      <Timers loading={loading} />
      <div style={{ display: "none" }} id="script-wrapper"></div>
      {<AppLoader loading={loading || imageLoading} />}
      {errorDeskShowing ? (
        <ErrorDesk
          text={error && errorTexts[error]}
          hideRefresh={["desktopAccess", "telegramAccess"].includes(error)}
        />
      ) : (
        <>
          <Header />
          <AppRouter />
          <MobileNav />
        </>
      )}
    </main>
  );
};

export default AppContent;
