import React from "react";

import "./ErrorDesk.scss";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import { useSelector } from "react-redux";
import { TRANSLATIONS } from "assets/translations";
import MainBtn from "components/layout/MainBtn/MainBtn";

const { refreshText } = TRANSLATIONS.errorDesk;

const ErrorDesk = ({ text, hideRefresh }) => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  return (
    <TransitionProvider
      inProp={true}
      style={TransitionStyleTypes.opacity}
      className="error-desk"
    >
      <h3 className="error-desk__text">{text[siteLanguage]}</h3>
      {!hideRefresh && (
        <MainBtn
          asSecondary
          className="error-desk__btn"
          onClick={() => window.location.reload()}
        >
          {refreshText[siteLanguage]}
        </MainBtn>
      )}{" "}
    </TransitionProvider>
  );
};

export default ErrorDesk;
