import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurAd } from "utils/adRouter";
import { getLSItem, setLSItem } from "utils/localStorage";

export const useGlobalAdController = (spotId, scsClb, errClb, dependencies) => {
  const routes = useSelector((state) => state.adRouter.routes);
  const tgId = useSelector((state) => state.profile.tgData?.tgId);
  const [onclickaAd, setOnclickaAd] = useState(null);
  const [myBidAd, setMyBidAd] = useState(null);
  const { service, type: t, id } = getCurAd(routes, spotId);

  const type = `${service}-${t}`;

  let AdController = null;
  if (type === "adsgram-v") {
    AdController = window.Adsgram?.init({ blockId: id });
  }

  const onSuccess = () => {
    scsClb?.();
    setLSItem(spotId, Date.now());
  };

  useEffect(
    () => {
      if (tgId) {
        if (type === "onclicka-v") {
          window
            .initCdTma?.({ id })
            .then((show) => {
              setOnclickaAd((prev) => show);
            })
            .catch((e) => console.log(e));
        }
        if (type === "mybid-v") {
          window
            .initCdTma?.({ id })
            .then((show) => {
              setMyBidAd((prev) => show);
            })
            .catch((e) => console.log(e));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies ? [...dependencies, tgId] : [tgId]
  );

  const onShowAd = () => {
    const showCurAd = () => {
      console.log("showCurAd", type);

      switch (type) {
        case "adsgram-v": {
          AdController.show()
            .then((result) => {
              if (result.done) {
                onSuccess();
              }
            })
            .catch((result) => {
              console.error("err", result);
            });
          break;
        }
        case "onclicka-v": {
          if (!onclickaAd) {
            errClb?.();
            return;
          }
          onclickaAd?.()
            .then((res) => {
              onSuccess();
            })
            .catch((err) => {
              errClb?.(err);
            });
          break;
        }
        case "mybid-v": {
          if (!myBidAd) {
            errClb?.();
            return;
          }
          myBidAd?.()
            .then(() => onSuccess())
            .catch((err) => {
              errClb?.(err);
            });
          break;
        }
        case "sonar-v": {
          const unitID = id.split("*")[1];

          window.Sonar.show({
            adUnit: unitID || "bonuses",
            scsClb,
            errClb,
          })
            .then((msg) => {
              if (msg?.status === "error") {
                errClb?.(msg);
                return;
              }
              onSuccess();
            })
            .catch((err) => {
              console.error(err);
              errClb?.();
            });

          break;
        }
        case "adsgram-i": {
          (async () => {
            try {
              await window.Adsgram.init({ blockId: `int-${id}` }).show();
              onSuccess();
            } catch (error) {
              errClb?.();
            }
          })();
          break;
        }
        default: {
          break;
        }
      }
    };

    if (process.env.NODE_ENV === "development") {
      showCurAd();
    } else {
      getLSItem(spotId, (_, data) => {
        if (data && +data + 20000 >= Date.now()) {
          errClb?.({ timer: +data + 20000 - Date.now() });
          return;
        }

        showCurAd();
      });
    }
  };

  return onShowAd;
};
