import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import "./ImagePuzzleGame.scss";
import {
  PUZZLE_PIECES_COUNT,
  PUZZLE_ROWS,
} from "../../../constants/imagePuzzle";
import ImagePuzzleModal from "../ImagePuzzleModal/ImagePuzzleModal";
import { PUZZLE_MEDIA_BASE_PATH } from "store/actions/puzzle";
import { TRANSLATIONS } from "assets/translations";
import { useDispatch, useSelector } from "react-redux";
import { generateEvent, generateEventTypes } from "store/actions/hackTerminal";
import { useCopyRef } from "hooks/useCopyRef";
import { shuffleArray } from "../../../utils/shuffleArray";
import { useGlobalAdController } from "hooks/useGlobalAdController";
import { spots } from "constants/adRouter";
import { fetchAd } from "store/actions/adRouter";

const { shufflePiecesText, restartText, startGameText } =
  TRANSLATIONS.imagePuzzle;

const pieces = Array.from({ length: PUZZLE_PIECES_COUNT }).map(
  (item, index) => index
);

const shuffledPeaces = shuffleArray(pieces);
const initialBoardPieces = Array.from({ length: PUZZLE_PIECES_COUNT });

const ImagePuzzlePiece = ({
  index,
  image,
  pieceSize,
  placeIndex,
  className,
  onMouseDown,
  replace,
}) => {
  const ref = useRef(null);
  const rowIndex = Math.floor(index / PUZZLE_ROWS);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(
        "touchstart",
        (e) => {
          e.preventDefault();
          onMouseDown(index, placeIndex, replace);
        },
        { passive: false }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={ref}
      onDragStart={(e) => e.preventDefault()}
      data-placeindex={placeIndex}
      data-index={index}
      style={{
        backgroundImage:
          index !== undefined
            ? `url(${PUZZLE_MEDIA_BASE_PATH}/images/${image})`
            : undefined,
        backgroundSize: `${pieceSize * PUZZLE_ROWS}px ${
          (pieceSize * PUZZLE_PIECES_COUNT) / PUZZLE_ROWS
        }px`,
        backgroundPosition: `-${index * pieceSize}px -${
          rowIndex * pieceSize
        }px`,
      }}
      className={`image-puzzle-game__board-item ${
        index !== undefined ? "withBg" : ""
      } ${className || ""}`}
      onMouseDown={onMouseDown}
    >
      {process.env.NODE_ENV === "development" ? index : undefined}
    </div>
  );
};

const DraggablePuzzlePiece = ({
  draggableIndex,
  pieceSize,
  image,
  draggablePlaceIndex,
  board,
  dragoverPlaceIndex,
  setDragoverPlaceIndex,
  setDragoverIndex,
}) => {
  const initialPositions = [[-1000, -1000]];
  const [position, setPosition] = useState(initialPositions);
  const draggableIndexRef = useCopyRef(draggableIndex);
  const pieceSizeRef = useCopyRef(pieceSize);

  useEffect(() => {
    if (draggableIndex === -1) {
      setPosition([initialPositions]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggableIndex]);

  useLayoutEffect(() => {
    if (!board) return;
    const onMouseMove = (e) => {
      if (draggableIndexRef.current === -1) return;
      e.preventDefault();

      const posX = e.touches ? e.touches[0].clientX : e.clientX;
      const posY =
        (e.touches ? e.touches[0].clientY : e.clientY) + window.scrollY;

      setPosition([posX, posY]);

      const piecesPositions = [...board.children].map((item) => ({
        x: item.offsetLeft,
        y: item.offsetTop,
        index: item.dataset.index && +item.dataset.index,
        placeIndex: item.dataset.placeindex && +item.dataset.placeindex,
      }));
      const pieceSize = pieceSizeRef.current;
      const curPiece = piecesPositions.find(
        (item) =>
          posX >= item.x &&
          posX <= item.x + pieceSize &&
          posY >= item.y &&
          posY <= item.y + pieceSize
      );

      if (curPiece) {
        setDragoverPlaceIndex(curPiece.placeIndex);
        setDragoverIndex(curPiece.index);
      } else {
        if (dragoverPlaceIndex !== -1) setDragoverPlaceIndex(-1);
      }
    };
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("touchmove", onMouseMove, {
      passive: false,
    });
    return () => {
      document.body.removeEventListener("mousemove", onMouseMove);
      document.body.removeEventListener("touchmove", onMouseMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board]);

  if (draggableIndex === -1 || !position.some((item) => item >= 0)) return null;

  return (
    <div
      className="image-puzzle-game__draggable-peace"
      style={{
        left: `${position[0]}px`,
        top: `${position[1]}px`,
      }}
    >
      <ImagePuzzlePiece
        index={draggableIndex}
        placeIndex={draggablePlaceIndex}
        image={image}
        pieceSize={pieceSize}
        className={"absolute"}
      />
    </div>
  );
};

const ImagePuzzleGame = ({
  startTimer,
  restartTimer,
  stopTimer,
  fetchImage,
  setCollectedPeacesCount,
  timerFinished,
  image,
  initBanner,
}) => {
  const dispatch = useDispatch();
  const boardRef = useRef(null);
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const [pieceSize, setPieceSize] = useState(0);
  const [boardPieces, setBoardPieces] = useState(initialBoardPieces);
  const [listPieces, setListPieces] = useState(shuffledPeaces);
  const [draggableIndex, setDraggableIndex] = useState(-1);
  const [draggablePlaceIndex, setDraggablePlaceIndex] = useState(-1);
  const [dragoverPlaceIndex, setDragoverPlaceIndex] = useState(-1);
  const [dragoverIndex, setDragoverIndex] = useState(-1);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [bonusType, setBonusType] = useState("");
  const [bonusValue, setBonusValue] = useState("");
  const [isReplace, setIsReplace] = useState(null);
  const [status, setStatus] = useState(0); // -1 - lose, 1 - win
  const draggableIndexRef = useCopyRef(draggableIndex);
  const dragoverPlaceIndexRef = useCopyRef(dragoverPlaceIndex);
  const dragoverIndexRef = useCopyRef(dragoverIndex);
  const boardPiecesRef = useCopyRef(boardPieces);
  const draggablePlaceIndexRef = useCopyRef(draggablePlaceIndex);
  const isReplaceRef = useCopyRef(isReplace);
  const isGameStartedRef = useCopyRef(isGameStarted);

  const onShowAd = useGlobalAdController(spots.miniPuzzleVideo, () =>
    dispatch(fetchAd(spots.miniPuzzleVideo))
  );

  useEffect(() => {
    if (boardRef.current) {
      setPieceSize(
        boardRef.current.firstElementChild.getBoundingClientRect().width
      );
    }
  }, [boardRef]);

  useEffect(() => {
    if (!modalOpened) {
      initBanner?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpened]);

  useEffect(() => {
    const onMouseUp = () => {
      setDraggableIndex(-1);
      setDraggablePlaceIndex(-1);
      setDragoverPlaceIndex(-1);
      setIsReplace(null);
      if (
        draggableIndexRef.current === -1 ||
        dragoverPlaceIndexRef.current === -1 ||
        dragoverPlaceIndexRef.current === dragoverIndexRef.current
      ) {
        return;
      }

      if (!isReplaceRef.current) {
        if (dragoverIndexRef.current) {
          setListPieces((prevState) => {
            const copy = [...prevState];

            const updatingIndex = prevState.findIndex(
              (item) => item === draggableIndexRef.current
            );

            if (updatingIndex !== -1) {
              copy[updatingIndex] = dragoverIndexRef.current;
            }

            return copy;
          });
        } else {
          setListPieces((prevState) =>
            prevState.filter((item) => item !== draggableIndexRef.current)
          );
        }
      }

      const updatingBoardPieces = [...boardPiecesRef.current];
      updatingBoardPieces[dragoverPlaceIndexRef.current] =
        draggableIndexRef.current;

      if (isReplaceRef.current) {
        updatingBoardPieces[draggablePlaceIndexRef.current] =
          dragoverIndexRef.current;
      }
      setBoardPieces(updatingBoardPieces);
    };

    window.addEventListener("mouseup", onMouseUp, { passive: false });
    window.addEventListener("touchend", onMouseUp, { passive: false });

    return () => {
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("touchend", onMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timerFinished) {
      stopTimer();
      setStatus(-1);
      setModalOpened(true);
      onShowAd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerFinished]);

  useEffect(() => {
    const collectedPeaces = boardPieces.filter(
      (item, index) => item !== undefined && item === index
    ).length;
    setCollectedPeacesCount(collectedPeaces);

    if (collectedPeaces === PUZZLE_PIECES_COUNT) {
      (async () => {
        try {
          const res = await dispatch(
            generateEvent(generateEventTypes.puzzle_game)
          );

          setBonusType(res.bonus_type);
          setBonusValue(res.bonus_value);
          setStatus(1);
          setModalOpened(true);
          onShowAd();
          stopTimer();
        } catch (error) {}
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardPieces]);

  const onMouseDown = (index, placeIndex, replace) => {
    if (
      index === undefined ||
      !isGameStartedRef.current ||
      (replace && index === placeIndex)
    )
      return;
    setIsReplace(!!replace);
    setDraggableIndex(index);
    setDraggablePlaceIndex(placeIndex);
  };

  const onRestart = async () => {
    if (!isGameStarted) {
      setIsGameStarted(true);
      startTimer();
    } else {
      setIsGameStarted(false);
      restartTimer();
      setBoardPieces(initialBoardPieces);
      setListPieces(shuffleArray(pieces));
      setStatus(0);
      await fetchImage();
    }
  };

  const renderPieces = (array, className, replace) =>
    array.map((index, i) => {
      return (
        <ImagePuzzlePiece
          index={index}
          image={image}
          pieceSize={pieceSize}
          placeIndex={i}
          className={`${className} ${
            dragoverPlaceIndex === i ? "dragover" : ""
          }`}
          key={`${index}-${i}`}
          replace={replace}
          onMouseDown={() => onMouseDown(index, i, replace)}
        />
      );
    });

  return (
    <>
      <div className="image-puzzle-game">
        <DraggablePuzzlePiece
          draggableIndex={draggableIndex}
          pieceSize={pieceSize}
          image={image}
          draggablePlaceIndex={draggablePlaceIndex}
          setDragoverPlaceIndex={setDragoverPlaceIndex}
          setDragoverIndex={setDragoverIndex}
          dragoverPlaceIndex={dragoverPlaceIndexRef.current}
          board={boardRef.current}
        />
        <div
          className="image-puzzle-game__board"
          style={{ gridTemplateColumns: `repeat(${PUZZLE_ROWS}, 1fr)` }}
          ref={boardRef}
        >
          {renderPieces(boardPieces, undefined, true)}
        </div>
        <div className="image-puzzle-game__pieces-list">
          {renderPieces(listPieces, "image-puzzle-game__piece")}
        </div>
        <div className="image-puzzle-game__footer">
          <button
            onClick={() =>
              setListPieces((prevState) => shuffleArray(prevState))
            }
            className="image-puzzle-game__footer-btn"
          >
            {shufflePiecesText[siteLanguage]}
          </button>
          <button onClick={onRestart} className="image-puzzle-game__footer-btn">
            {isGameStarted
              ? restartText[siteLanguage]
              : startGameText[siteLanguage]}
          </button>
        </div>
      </div>
      <ImagePuzzleModal
        activePopup={modalOpened}
        isLose={status < 0}
        onReset={() => {
          onRestart();
          setModalOpened(false);
        }}
        bonusValue={bonusValue}
        bonusType={bonusType}
      />
    </>
  );
};

export default ImagePuzzleGame;
