import React from 'react'
import Svg from '../Svg/Svg'
import { inputBgIcon } from 'assets/svg'

import "./MainInput.scss"

const MainInput = ({value,className,invalid,...args}) => {
  return (
    <label className="main-input">
    <input
      value={value}
      className={`main-input__field ${invalid ? "invalid" : ""}`}
     {...args}
    />
    <Svg id={inputBgIcon} className="main-input__bg" />
  </label>
  )
}

export default MainInput
