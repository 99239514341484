import React, { Fragment } from "react";
import { TRANSLATIONS } from "../../../../assets/translations";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useSelector } from "react-redux";
import { improoveBuilding } from "../../../../store/actions/buildings";
import { walletPagePath } from "router/path";
import { BUILD_IMAGES } from "../../../../constants/buildImages";
import {
  buildsAccentImg,
  buildsItemBgImg,
  getDarkIconImg,
  resIconImg,
  statsResImg,
  statsTokenImg,
} from "assets/images";
import { buildsProgressCircleIcon, buildsUpgradeBgIcon } from "assets/svg";
import Svg from "../../../layout/Svg/Svg";
import MainBtn from "../../../layout/MainBtn/MainBtn";
import { formatNumber } from "../../../../utils/formatNumber";
import { statsImages } from "constants/stats";
import "./BuildListItem.scss";
import { formatMilliseconds } from "utils/formatMilliseconds";
const {
  hourText,
  buyText,
  levelText,
  upgradesText,
  lvlText,
  forText,
  improveText,
  openText,
  minText,
  insufficientLevelText,
  maxLevelText,
  dayText,
  unavailableForImprovementText,
} = TRANSLATIONS.buildings;

const incomeTypes = {
  resources_per_hour: {
    statImg: resIconImg,
  },
  matter_per_hour: {
    statImg: getDarkIconImg,
  },
  tokens_per_hour: {
    statImg: statsTokenImg,
  },
  bonus: {
    statImg: statsResImg,
  },
};

const BuildListItem = ({
  image,
  id_building,
  income,
  completedUpgradeLevel,
  nextUpgradeLevel,
  buildingLevel,
  notBuyed,
  stats,
  openUpgradePopup,
  siteLanguage,
  max_level,
  type,
  setMatterPollModalOpened,
  setCasinoModalOpened,
  showTooltip,
  price,
  curLevelMode,
  ...props
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const poolBonusValue = useSelector((state) => state.profile.poolBonusValue);
  const token_to_ton_flag = useSelector(
    (state) => state.profile.token_to_ton_flag
  );
  const exchange_rate_timer = useSelector(
    (state) => state.profile.exchange_rate_timer
  );
  const casinoBonusValue = useSelector(
    (state) => state.profile.casinoBonusValue
  );
  const max_tokens_to_ton = useSelector(
    (state) => state.profile.max_tokens_to_ton
  );
  const isExchangePoint = id_building === 17;
  const isIncomeCityUpgrade = income.type === "special";
  const isIncomeSpecialUpgrade = income.type === "special_building";
  const isSynthesizer = id_building === 14;
  const isExchange = id_building === 13;
  const isCasino = id_building === 15;
  const isWarehouses = id_building === 16;

  let nextLevel = 1;

  if (curLevelMode) {
    nextLevel =
      curLevelMode === "max" && price?.value_max?.levels
        ? price.value_max.levels
        : curLevelMode;
  }

  const onImprooveBuild = async (id) => {
    setLoading(true);
    try {
      await dispatch(improoveBuilding(id, nextLevel));
    } catch (error) {
      showTooltip(
        id === 17 ? insufficientLevelText : unavailableForImprovementText
      );
    }

    setLoading(false);
  };

  const onOpen = () => {
    if (id_building === 13) {
      navigate(`${walletPagePath}#matterToToken`);
    } else if (id_building === 15) {
      setCasinoModalOpened(true);
    } else if (isExchangePoint) {
      navigate(`${walletPagePath}#tokenToTon`);
    } else {
      setMatterPollModalOpened(true);
    }
  };

  if (!nextUpgradeLevel) nextUpgradeLevel = 0;
  if (!completedUpgradeLevel) completedUpgradeLevel = 0;
  const lastLevel = nextUpgradeLevel || completedUpgradeLevel;

  // +5мин
  let incomeValue = formatNumber(income.value);
  let incomeType = income.type;

  if (isCasino) {
    incomeValue = formatNumber(casinoBonusValue, 3);
    incomeType = "tokens_per_hour";
  }
  if (isSynthesizer) {
    incomeType = "matter_per_hour";
    incomeValue = formatNumber(poolBonusValue, 3);
  }
  if (isWarehouses) {
    incomeValue = `+${formatNumber(+incomeValue, 3)} ${minText[siteLanguage]}`;
  }

  const curPrice = !buildingLevel
    ? price.value
    : price[curLevelMode ? `value_${curLevelMode}` : "value"];

  const isPriceEnogh =
    typeof price.value === "object"
      ? Object.keys(price.value).every((key) => price.value[key] <= stats[key])
      : price.value <= stats[price.type];

  const renderPrice = (type, value) => (
    <>
      <span>
        {type === "matter" || type === "tokens"
          ? (+value).toFixed(3)
          : formatNumber(value)}
      </span>{" "}
      <img src={statsImages[type]} alt="" />
    </>
  );

  return (
    <li className="build-list-item" key={id_building}>
      <div className="build-list-item__card">
        <div className="build-list-item__image">
          <img
            className="cover-image"
            src={BUILD_IMAGES[props.name_eng]}
            alt={props.name_eng}
          />
        </div>
        <div className="build-list-item__content">
          <div className="build-list-item__titleBlock">
            <h2 className="build-list-item__title">
              {props[`name_${siteLanguage}`] || props.name_rus}
            </h2>
          </div>
          {type === "building" && (
            <>
              <p className={"build-list-item__progress-title"}>
                {upgradesText[siteLanguage]}
              </p>
              <div className="build-list-item__progress">
                {Array.from({
                  length: 6,
                }).map((_, upgradeIndex) => {
                  const lastIndex = +nextUpgradeLevel || +completedUpgradeLevel;
                  return (
                    <button
                      disabled={
                        upgradeIndex > lastIndex - 1 ||
                        loading ||
                        completedUpgradeLevel >= upgradeIndex + 1
                      }
                      className={`build-list-item__progress-item ${
                        upgradeIndex >= lastIndex ? "hidden" : ""
                      }`}
                      key={upgradeIndex}
                      onClick={() => {
                        openUpgradePopup(upgradeIndex, id_building);
                      }}
                    >
                      <div className="build-list-item__progress-card">
                        <p className="build-list-item__progress-value">
                          {upgradeIndex + 1}
                        </p>
                      </div>
                      <div className="build-list-item__progress-bg">
                        <img
                          src={
                            completedUpgradeLevel === upgradeIndex + 1
                              ? buildsAccentImg
                              : buildsItemBgImg
                          }
                          alt="upgrade"
                        />
                      </div>
                    </button>
                  );
                })}

                {lastLevel > 3 && (
                  <div className="build-list-item__progress-circle build-list-item__progress-circle_first">
                    <Svg id={buildsProgressCircleIcon} />
                  </div>
                )}
                {lastLevel > 4 && (
                  <div className="build-list-item__progress-circle build-list-item__progress-circle_second">
                    <Svg id={buildsProgressCircleIcon} />
                  </div>
                )}
              </div>
            </>
          )}
          {([13, 14, 15].includes(id_building) ||
            (id_building === 17 && !token_to_ton_flag)) && (
            <MainBtn onClick={onOpen} className="build-list-item__enter-btn">
              {openText[siteLanguage]}
            </MainBtn>
          )}
          {id_building === 17 && token_to_ton_flag && (
            <p className="build-list-item__timer">
              {formatMilliseconds(exchange_rate_timer)}
            </p>
          )}
        </div>
        <div className="build-list-item__token">
          <div className="build-list-item__earn f-center-center">
            {!isExchangePoint
              ? (isIncomeCityUpgrade || isIncomeSpecialUpgrade) &&
                !isSynthesizer &&
                !isCasino &&
                !isWarehouses
                ? `${!isExchange ? "+" : ""}${incomeValue}%`
                : incomeValue
              : `${formatNumber(max_tokens_to_ton)}/${dayText[siteLanguage]}`}

            {((!isIncomeCityUpgrade && !isIncomeSpecialUpgrade) ||
              isSynthesizer ||
              isCasino) && (
              <img src={incomeTypes[incomeType]?.statImg} alt="" />
            )}
            {!isIncomeCityUpgrade &&
              !isIncomeSpecialUpgrade &&
              hourText[siteLanguage]}
          </div>
          <div className="build-list-item__token-main">
            {!!buildingLevel && (
              <div className="build-list-item__level f-center-center">
                {buildingLevel} {lvlText[siteLanguage]}
              </div>
            )}
            <button
              disabled={
                !isPriceEnogh ||
                loading ||
                (isExchangePoint && buildingLevel === 10)
              }
              onClick={() => {
                onImprooveBuild(id_building);
              }}
              type="button"
              className="build-list-item__upgrade f-center-center"
            >
              <div className="build-list-item__upgrade-content">
                {isExchangePoint && buildingLevel === 10
                  ? null
                  : !!buildingLevel && (
                      <p className="build-list-item__upgrade-lvl">
                        <span>+{nextLevel}</span> {levelText[siteLanguage]}
                      </p>
                    )}
                <p className="build-list-item__upgrade-title">
                  {max_level && buildingLevel === max_level
                    ? maxLevelText[siteLanguage]
                    : !buildingLevel
                    ? buyText[siteLanguage]
                    : improveText[siteLanguage]}
                </p>
                {!(isExchangePoint && buildingLevel === 10) && (
                  <p className="build-list-item__upgrade-price f-center-center">
                    {forText[siteLanguage]}{" "}
                    {typeof curPrice === "object" ? (
                      <div className="build-list-item__upgrade-price-list">
                        {Object.keys(curPrice)
                          .filter((item) => item !== "levels")
                          .map((item, index) => (
                            <div className="f-center-center" key={index}>
                              {renderPrice(item, curPrice[item])}
                            </div>
                          ))}
                      </div>
                    ) : (
                      renderPrice(price.type, curPrice)
                    )}
                  </p>
                )}
              </div>
              <div className="build-list-item__upgrade-bg">
                <Svg id={buildsUpgradeBgIcon} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default BuildListItem;
