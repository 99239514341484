import { updateAdRoute } from "store/slices/adRouterSlice";
import { fetchRequest } from "store/tools/fetchTools";
import { getPlatformType } from "utils/getPlatformType";

const getAdUrl = "ad_router/";

export const fetchAd = (slotId) => async (dispatch, getState) => {
  try {
    const tgId = getState().profile.tgData.tgId;
    const siteLanguage = getState().ui.siteLanguage;
    const routes = getState().adRouter.routes;
    const isMobile = getPlatformType(window.Telegram?.WebApp);
    const platformType = isMobile ? "mobile" : "desktop";


    const data = await fetchRequest(
      `${getAdUrl}?slot_id=${slotId}&device_type=${platformType}&ad_id=${routes[slotId][platformType]}`,
      tgId,
      siteLanguage
    );
    dispatch(updateAdRoute(data.ad_router));
  } catch (error) {
    console.error("error", error);
  }
  // eslint-disable-next-line no-undef
};
