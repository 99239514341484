import {
  banksImage,
  colliderImage,
  dumpsImage,
  industrialFarmsImage,
  internationalExchangeImage,
  darkМatterSynthesizerImage,
  machineBuildingPlantsImage,
  metallurgicalPlantsImage,
  minesImage,
  powerPlantsImage,
  repairPlantsImage,
  researchInstitutesImage,
  residentialComplexesImage,
  roadSystemImage,
  casinoImage,
  warehousesImage,
  exchangeRateImage,
} from "../assets/images";

export const BUILD_IMAGES = {
  Dumps: dumpsImage,
  Mines: minesImage,
  "Industrial Farms": industrialFarmsImage,
  "Power Plants": powerPlantsImage,
  "Metallurgical Plants": metallurgicalPlantsImage,
  "Repair Plants": repairPlantsImage,
  "Machine-Building Plants": machineBuildingPlantsImage,
  "Residential Complexes": residentialComplexesImage,
  Banks: banksImage,
  "Research Institutes": researchInstitutesImage,
  Collider: colliderImage,
  "Road System": roadSystemImage,
  "International Exchange": internationalExchangeImage,
  "Dark Matter Synthesizer": darkМatterSynthesizerImage,
  Casino: casinoImage,
  Warehouses: warehousesImage,
  "Exchange Point": exchangeRateImage,
};
