import React, { useEffect, useRef, useState } from "react";
import "./TasksList.scss";
import Svg from "components/layout/Svg/Svg";
import { resIconImg } from "assets/images";
import {
  taskDailyBgIcon,
  taskGetBtnBgIcon,
  taskListRewardBgIcon,
  taskSubscribeBtnBgIcon,
} from "../../../assets/svg";
import { TRANSLATIONS } from "assets/translations";
import { useDispatch, useSelector } from "react-redux";
import { formatMilliseconds } from "utils/formatMilliseconds";
import { formatNumber } from "utils/formatNumber";
import {
  completeWallgramTask,
  fetchDailyCollect,
  rewardTraffyTask,
  subscribePartnersTask,
  subscribeTask,
  updateWallgramtaskStatus,
} from "store/actions/profile";
import Tooltip from "components/layout/Tooltip/Tooltip";
import { useTooltip } from "hooks/usetooltip";
import { updateStats, updateSubscribeTask } from "store/slices/profileSlice";
import { useNavigate } from "react-router-dom";
import { hackTerminalPagePath, imagePuzzlePagePath } from "router/path";
import { spots } from "../../../constants/adRouter";
import { getCurAd } from "utils/adRouter";
import { fetchAd } from "store/actions/adRouter";
import { useLazy } from "useLazy";
// Import Taddy SDK
import RegularTaskItem from "./RegularTaskItem/RegularTaskItem";

const {
  dailyLoginRewardText,
  dayText,
  collectText,
  getText,
  lvlText,
  notSubscribedText,
  spenderText,
  checkText,
  receivedText,
  openText,
  hackingTerminalText,
  supportProjectText,
} = TRANSLATIONS.tasks;

const AdBanner = ({ keyName }) => {
  const dispatch = useDispatch();
  const routes = useSelector((state) => state.adRouter.routes);
  const { isInView, ref } = useLazy();

  const spotId = `taskListBanner${+keyName.replace("ad", "") + 1}`;
  const { id, curAd } = getCurAd(routes, spots[spotId]);

  useEffect(() => {
    if (isInView) {
      dispatch(fetchAd(spots[spotId]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  useEffect(() => {
    if ("init" in curAd) {
      curAd.init(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ("render" in curAd) {
    return (
      <div ref={ref} className="tasks-list__banner">
        {curAd.render(id)}
      </div>
    );
  }

  return null;
};

const SpecialTaskItem = ({ name, path, siteLanguage }) => {
  const navigate = useNavigate();

  return (
    <div className="tasks-list__card">
      <div className="tasks-list__main f-center-center">
        <h3 className="tasks-list__title">{name}</h3>
        <div className="tasks-list__offer f-end">
          <div className="tasks-list__btns f-center">
            <button
              onClick={() => navigate(path)}
              type="button"
              className="tasks-list__btn"
            >
              {openText[siteLanguage]}
              <div className="tasks-list__btn-bg">
                <Svg id={taskSubscribeBtnBgIcon} />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdvancedTaskItem = ({
  title,
  description,
  level,
  progress,
  siteLanguage,
}) => (
  <div className="tasks-list__card">
    <div className="tasks-list__main">
      <div className="tasks-list__head f-center-jcsb">
        <h3 className="tasks-list__title">{title}</h3>
        <p className="tasks-list__lvl">
          <span>{level}</span> {lvlText[siteLanguage]}
        </p>
      </div>
      <p className="tasks-list__text">{description}</p>
      <div className="tasks-list__offer f-center-jcsb">
        <div className="tasks-list__tag">
          {spenderText[siteLanguage]}
          <div className="tasks-list__tag-bg">
            <Svg id={taskListRewardBgIcon} />
          </div>
        </div>
        <div className="tasks-list__btns f-center">
          <p className="tasks-list__count">
            {progress[0]} <span>/ {progress[10]}</span>
          </p>
          <button type="button" className="tasks-list__btn">
            {getText[siteLanguage]}
            <div className="tasks-list__btn-bg">
              <Svg id={taskGetBtnBgIcon} />
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
);

const TasksList = ({ activeTab }) => {
  const dispatch = useDispatch();
  const tgId = useSelector((state) => state.profile.tgData.tgId);
  const stats = useSelector((state) => state.profile.stats);
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const subscribe = useSelector((state) => state.profile.subscribe);
  const wallgramTasks = useSelector((state) => state.profile.wallgramTasks);
  const routes = useSelector((state) => state.adRouter.routes);
  const taddy = useSelector((state) => state.tasks.taddy);
  const [taddyTasks, setTaddyTasks] = useState([]);

  const subscribe_partners = useSelector(
    (state) => state.profile.subscribe_partners
  );
  const day = useSelector((state) => state.profile.dailylogin.day);
  const enable = useSelector((state) => state.profile.dailylogin.enable);
  const end_of_day = useSelector(
    (state) => state.profile.dailylogin.end_of_day
  );
  const total_bonus = useSelector(
    (state) => state.profile.dailylogin.total_bonus
  );
  const taddyBonus = useSelector((state) => state.tasks.bonus);

  const traffyTasksRef = useRef(null);
  const taskRef = useRef(null);

  const { openTooltip, show } = useTooltip();
  const { id, curAd } = getCurAd(routes, spots.taskListBanner1);

  const subscribeTasks = Object.keys(subscribe).map((key) => {
    return {
      name: key,
      ...subscribe[key],
    };
  });
  const subscribePartnersTasks = Object.keys(subscribe_partners).map((key) => ({
    keyName: key,
    ...subscribe_partners[key],
  }));
  console.log({ wallgramTasks });

  const tasks = {
    regular: [
      ...subscribeTasks,
      ...subscribePartnersTasks,
      ...wallgramTasks,
      ...taddyTasks,
    ],
    advanced: [],
    special: [
      {
        name: hackingTerminalText[siteLanguage],
        path: hackTerminalPagePath,
      },
      {
        name: "MINI PUZZLE",
        path: imagePuzzlePagePath,
      },
    ],
  };

  const setFormatedTaddyTasks = (tasks) => {
    setTaddyTasks(
      tasks.map((task) => ({
        ...task,
        ...taddyBonus,
        name: task.title,
        is_subscribed: false,
        is_teddy: true,
      }))
    );
  };

  const fetchTaddyTasks = async () => {
    const tasks = await taddy?.tasks({ limit: 5, autoImpressions: true });
    console.log(tasks);
    return tasks;
    // description
    // id
    // image
    // link
    // title
  };

  useEffect(() => {
    if (!taddy || !tgId) return;
    (async () => {
      try {
        const taddyTasks = await fetchTaddyTasks();
        setFormatedTaddyTasks(taddyTasks);
      } catch (error) {
        console.error(error);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taddy, tgId]);

  useEffect(() => {
    if (tgId) {
      (async () => {
        await dispatch(fetchAd(spots.taskListBanner1));
      })();
      if ("init" in curAd) {
        curAd.init(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgId]);

  useEffect(() => {
    if (traffyTasksRef.current) {
      const onTaskLoad = () => {};
      const onTaskRender = (
        changeReward,
        changeCardTitle,
        changeDescription,
        changeButtonCheckText
      ) => {
        changeReward("0.01");
        changeDescription("200K");
        changeButtonCheckText(checkText[siteLanguage]);
      };
      const onTaskReward = () => {
        dispatch(rewardTraffyTask());
      };
      const onTaskReject = openTooltip;
      window.Traffy.renderTasks(traffyTasksRef.current, {
        max_tasks: 1,
        onTaskLoad,
        onTaskRender,
        onTaskReward,
        onTaskReject,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traffyTasksRef]);

  // useEffect(() => {
  //   const handler = (event) => {
  //     console.log({ event });
  //     // event.detail contains your block id
  //   };
  //   const task = taskRef.current;

  //   if (task) {
  //     task.addEventListener("reward", handler);
  //   }

  //   return () => {
  //     if (task) {
  //       task.removeEventListener("reward", handler);
  //     }
  //   };
  // }, []);

  const subscribeTeddyTask = (id, subscribed = true) => {
    setTaddyTasks((prevState) => {
      const taskIndex = prevState.findIndex((item) => item.id === id);
      if (taskIndex === -1) return prevState;
      return prevState.with(taskIndex, {
        ...prevState[taskIndex],
        is_subscribed: subscribed,
      });
    });
  };

  const onSubscribe = async ({ name, link, wallgramTaskId, teddyTaskId }) => {
    try {
      let res = {};
      console.log({ teddyTaskId });

      if (teddyTaskId) {
        const taddyTasks = fetchTaddyTasks();
        if (taddyTasks.find((item) => item.id === teddyTaskId)) {
          throw new Error("task not completed");
        }
        dispatch(
          updateStats({
            ...stats,
            [taddyBonus.bonus_type]:
              stats[taddyBonus.bonus_type] + taddyBonus.bonus_size,
          })
        );
        setTaddyTasks((prevState) =>
          prevState.filter((task) => task.id === teddyTaskId)
        );
      } else if (wallgramTaskId) {
        await dispatch(completeWallgramTask(wallgramTaskId));
      } else if (link) {
        res = await dispatch(subscribePartnersTask(link, name));
      } else {
        res = await dispatch(subscribeTask(name));
      }
      console.log({ res });

      if (res?.status === 400 || res?.status === 500) {
        openTooltip();
        if (subscribe[name].is_subscribed) {
          dispatch(
            updateSubscribeTask({
              key: name,
              data: { ...subscribe[name], is_subscribed: false },
            })
          );
        }
      }
    } catch (error) {
      if (wallgramTaskId) {
        openTooltip();
        dispatch(updateWallgramtaskStatus(wallgramTaskId));
      }
      if (teddyTaskId) {
        openTooltip();
        subscribeTeddyTask(teddyTaskId, false);
      }
    }
  };

  return (
    <div className="tasks-list">
      <div className={`tasks-list__content-item`}>
        <ul>
          {!activeTab && (
            <>
              <li className="tasks-list__item">
                <div className="tasks-list__card">
                  <div className="tasks-list__daily f-center-jcsb">
                    <h3 className="tasks-list__title">
                      {dailyLoginRewardText[siteLanguage]}
                    </h3>
                    <div className="tasks-list__daily-date">
                      <p className="tasks-list__daily-title">
                        {dayText[siteLanguage]}
                      </p>
                      <p className="tasks-list__daily-value">{day}</p>
                      <div className="tasks-list__daily-bg">
                        <Svg id={taskDailyBgIcon} />
                      </div>
                    </div>
                    <div className="tasks-list__daily-info">
                      <div className="tasks-list__reward f-center-center">
                        <img src={resIconImg} alt="" />
                        {formatNumber(total_bonus)}
                        <div className="tasks-list__reward-bg">
                          <Svg id={taskListRewardBgIcon} />
                        </div>
                      </div>
                      {end_of_day ? (
                        <p className="tasks-list__timer">
                          {formatMilliseconds(end_of_day)}
                        </p>
                      ) : (
                        <button
                          onClick={() => dispatch(fetchDailyCollect())}
                          disabled={!enable}
                          type="button"
                          className="tasks-list__btn"
                        >
                          {collectText[siteLanguage]}
                          <div className="tasks-list__btn-bg">
                            <Svg id={taskGetBtnBgIcon} />
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </li>
              <li className="tasks-list__item">
                <div className="tasks-list__banner">{curAd.render(id)}</div>
              </li>
            </>
          )}
          {Object.values(tasks)[activeTab].map((task, index) => {
            let content = "";

            switch (activeTab) {
              case 0: {
                if (task?.keyName?.startsWith("ad")) {
                  content = (
                    <AdBanner
                      {...task[0]}
                      keyName={task?.keyName}
                      index={index}
                    />
                  );
                } else {
                  content = (
                    <RegularTaskItem
                      {...task}
                      siteLanguage={siteLanguage}
                      onSubscribe={onSubscribe}
                      subscribeTeddyTask={subscribeTeddyTask}
                    />
                  );
                }
                break;
              }
              case 1: {
                content = (
                  <AdvancedTaskItem {...task} siteLanguage={siteLanguage} />
                );
                break;
              }
              default:
                content = (
                  <SpecialTaskItem {...task} siteLanguage={siteLanguage} />
                );
            }

            return (
              <li className="tasks-list__item" key={index}>
                {content}
              </li>
            );
          })}
        </ul>
        <div
          style={{ display: activeTab ? "none" : "flex" }}
          className="tasks-list__traffy-container"
          ref={traffyTasksRef}
        ></div>
        <div
          style={{ display: activeTab ? "none" : "flex" }}
          className="tasks-list__traffy-container"
        ></div>

        {/* adsgram tasks */}
        <div
          style={{ display: !activeTab ? undefined : "none" }}
          className="tasks-list__card tasks-list__item tasks-list__main"
        >
          <adsgram-task
            data-block-id="task-7805"
            data-debug="false"
            className="tasks-list__adsgram-card"
            ref={taskRef}
          >
            <div className="tasks-list__adsgram-income" slot="reward">
              {supportProjectText[siteLanguage]}
            </div>
            <div slot="button">
              <button type="button" className="tasks-list__btn">
                {getText[siteLanguage]}
                <div className="tasks-list__btn-bg">
                  <Svg id={taskGetBtnBgIcon} />
                </div>
              </button>
            </div>
            <div slot="done">
              <button type="button" className="tasks-list__btn">
                {receivedText[siteLanguage]}
                <div className="tasks-list__btn-bg">
                  <Svg id={taskGetBtnBgIcon} />
                </div>
              </button>
            </div>
          </adsgram-task>
        </div>

        {/* taddy Tasks */}
      </div>
      <Tooltip show={show} text={notSubscribedText[siteLanguage]} />
    </div>
  );
};

export default TasksList;
