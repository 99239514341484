export const PUZZLE_MEDIA_BASE_PATH = "https://idledystopia.com/puzzle-media";
const randomImagePath = "/random-image";
const audioFilesPath = "/audioFiles";
const randomSplashPath = "/random-splash";
const randomHintPath = "/random-hint";

export const fecthPuzzleRandomImage = async () => {
  const res = await fetch(`${PUZZLE_MEDIA_BASE_PATH}${randomImagePath}`);
  const data = await res.json();

  return data;
};

export const fetchPuzzleAudioFiles = async () => {
  const res = await fetch(`${PUZZLE_MEDIA_BASE_PATH}${audioFilesPath}`);
  const data = await res.json();

  return data;
};


export const fecthRandomSplashImage = async () => {
  const res = await fetch(`${PUZZLE_MEDIA_BASE_PATH}${randomSplashPath}`);
  const data = await res.json();

  return data;
};

export const fecthRandomHint = async () => {
  const res = await fetch(`${PUZZLE_MEDIA_BASE_PATH}${randomHintPath}`);
  const data = await res.json();

  return data;
};