import React, { useState } from "react";
import "./BuildsWrapper.scss";
import BuildsTabBar from "../BuildsTabBar/BuildsTabBar";
import BuildsList from "../BuildsList/BuildsList";
import TutorialPopup from "components/TutorialPopup/TutorialPopup";
import { useTutorial } from "hooks/useTutorial";
import { lsProps } from "constants/lsProps";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";

const { startText, buildingsText, cityImproovmentText, specialBuildingsText } =
  TRANSLATIONS.buildings.tutorial;

export const tutorialSlides = (siteLanguage) => [
  {
    text: startText[siteLanguage],
  },
  {
    text: buildingsText[siteLanguage],
  },
  {
    text: cityImproovmentText[siteLanguage],
  },
  {
    text: specialBuildingsText[siteLanguage],
  },
];

const LEVEL_MODES = ["",5,10,25,"max"]

const BuildsWrapper = () => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  const [activeTab, setActiveTab] = useState("building");
  const [curLevelModeIndex,setCurLevelModeIndex] = useState(0)
  const { onClose, showTutorial } = useTutorial(
    lsProps.buildingsTutorialShowed
  );

  const changeLevelMode = () => {
    setCurLevelModeIndex(prevState => prevState === LEVEL_MODES.length -1  ? 0 : prevState + 1)
  }

const curLevelMode = LEVEL_MODES[curLevelModeIndex]

  return (
    <>
      <section className="container">
        <div className="builds-wrapper space-control">
          <BuildsTabBar activeTab={activeTab} setActiveTab={setActiveTab} changeLevelMode={changeLevelMode} curLevelMode={curLevelMode}/>
          <BuildsList activeTab={activeTab} curLevelMode={curLevelMode}/>
        </div>
      </section>
      <TutorialPopup
        active={showTutorial}
        onClose={onClose}
        slides={tutorialSlides(siteLanguage)}
      />
    </>
  );
};

export default BuildsWrapper;
