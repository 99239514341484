import React, { useEffect, useState } from "react";

import {
  tokenToTonImage,
  matterToTokenImage,
  depositImage,
  historyImage,
} from "../../../assets/images";

import "./WalletMain.scss";
import MatterToTokenWalletForm from "../MatterToTokenWalletForm/MatterToTokenWalletForm";
import TokenToTonWalletForm from "../TokenToTonWalletForm/TokenToTonWalletForm";
import WitdrawTonForm from "../WitdrawForm/WitdrawForm";
import { useTooltip } from "hooks/usetooltip";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";
import Tooltip from "components/layout/Tooltip/Tooltip";
import { useLocation } from "react-router-dom";

const { inDevelopmentText } = TRANSLATIONS;

const tabs = [
  {
    icon: matterToTokenImage,
    id: "matterToToken",
    component: <MatterToTokenWalletForm />,
  },
  {
    icon: tokenToTonImage,
    id: "tokenToTon",
    component: <TokenToTonWalletForm />,
  },
  { icon: depositImage, id: "deposit", component: <WitdrawTonForm /> },
  { icon: historyImage, id: "history", component: "" },
];

const WalletMain = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const location = useLocation();


  useEffect(() => {
    if (location.hash) {
      const curTab = tabs.find((item) => `#${item.id}` === location.hash);
      if (curTab) setActiveTab(curTab.id);
    }
  }, [location]);

  const { openTooltip, show } = useTooltip();

  const curTab = tabs.find((item) => item.id === activeTab);
  return (
    <div className="wallet-main">
      <div className="wallet-main__tabbar">
        {tabs.map((item) => (
          <button
            onClick={() => {
              if (item.id === "history") {
                openTooltip();
              } else setActiveTab(item.id);
            }}
            key={item.id}
            className={`wallet-main__tab ${
              activeTab === item.id ? "active" : ""
            }`}
          >
            <img
              src={item.icon}
              alt={item.id}
              className="wallet-main__tabIcon"
            />
          </button>
        ))}
      </div>
      {curTab && curTab.component}
      <Tooltip show={show} text={inDevelopmentText[siteLanguage]} />
    </div>
  );
};

export default WalletMain;
