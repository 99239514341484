import { generateSignature } from "services/crypto";

const API_KEY = process.env.REACT_APP_API_KEY;
export const baseUrl = "https://idledystopia.com/api/";
// export const baseUrl = "https://idledystopia.com/test/api/";
export const baseConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

// ENDPOINTS

export const fetchRequest = async (
  fetchUrl,
  tgId,
  language,
  method = "GET",
  body
) => {
  const config = baseConfig;
  const isAuth = !!tgId;
  let url = `${baseUrl}${fetchUrl}`;

  if (isAuth) {
    // eslint-disable-next-line no-undef
    const { signatureHex, timestamp } = generateSignature(API_KEY, tgId);

    // request bildings.research_institute.level&timestamp
    url += `${
      url.includes("?") ? "&" : "?"
    }language=${language}&id_tgrm=${tgId}&timestamp=${timestamp}&signature=${signatureHex}`;
  }

  const response = await fetch(url, {
    method: method,
    body: body && JSON.stringify(body),
    ...config,
  });

  // eslint-disable-next-line no-undef
  const resData = await response.json();

  if (!response.ok) {
    // eslint-disable-next-line no-throw-literal
    throw { message: resData, status: response.status };
  }
  return resData;
};
