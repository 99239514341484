import React from "react";
import { statsTokenImg, statsDarkImg, statsResImg } from "assets/images";
import Svg from "components/layout/Svg/Svg";
import { mainStatItemBgIcon, mainStatsBgIcon } from "assets/svg";

import "./MainStats.scss";
import { useSelector } from "react-redux";
import { formatNumber } from "utils/formatNumber";
import { TRANSLATIONS } from "assets/translations";
import { useNavigate } from "react-router-dom";
import { walletPagePath } from "router/path";

const { resourcesText, matterText, tokenText } = TRANSLATIONS.home;

const MainStats = () => {
  const naviagte = useNavigate();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const resources = useSelector((state) => state.profile.stats.resources);
  const matter = useSelector((state) => state.profile.stats.matter);
  const tokens = useSelector((state) => state.profile.stats.tokens);

  const stats = [
    {
      name: resourcesText[siteLanguage],
      icon: statsResImg,
      value: resources,
    },
    {
      name: matterText[siteLanguage],
      icon: statsDarkImg,
      value: matter,
    },
    {
      name: tokenText[siteLanguage],
      icon: statsTokenImg,
      value: tokens,
    },
  ];
  return (
    <div className="main-stats f-jcsb" onClick={() => naviagte(walletPagePath)}>
      {stats.map(({ name, icon, value }, index) => (
        <div className="main-stats__item" key={index}>
          <div className="main-stats__offer f-center">
            <div className="main-stats__icon">
              <img className="cover-image" src={icon} alt={name} />
            </div>
            <p className="main-stats__title">{name}</p>
          </div>
          <p className="main-stats__value">
            {" "}
            {[matterText[siteLanguage], tokenText[siteLanguage]].includes(name)
              ? (+value).toFixed(3)
              : formatNumber(value)}
          </p>
          <div className="main-stats__item-bg">
            <Svg id={mainStatItemBgIcon} />
          </div>
        </div>
      ))}

      <div className="main-stats__bg">
        <Svg id={mainStatsBgIcon} />
      </div>
    </div>
  );
};

export default MainStats;
