import { initAdsteraAd } from "utils/adstera";
import { initOnClicka } from "utils/onClicka";

export const spots = {
  collectModalBanner: "collect-modal-banner",
  resourcesBonusModalBanner: "resources-bonus-modal-banner",
  permanentBonusModalBanner: "permanent-bonus-modal-banner",
  buildUpgradeModalBanner: "build-upgrade-modal-banner",
  taskListBanner1: "task-list-banner1",
  taskListBanner2: "task-list-banner2",
  taskListBanner3: "task-list-banner3",
  hackTerminalBanner: "hack-terminal-banner",
  miniPuzzleBanner: "mini-puzzle-banner",
  collectModalVideo: "collect-modal-video",
  resourcesBonusModalVideo: "resources-bonus-modal-video",
  permanentBonusModalVideo: "permanent-bonus-modal-video",
  speedBonusVideo: "speed-bonus-video",
  buildUpgradeModalVideo: "build-upgrade-modal-video",
  hackTerminalVideo: "hack-terminal-video",
  miniPuzzleVideo: "mini-puzzle-video",
};

export const ads = {
  onclickaB6050153: "onclicka-b-6050153",
  onclickaB6052313: "onclicka-b-6052313",
  onclickaB6056755: "onclicka-b-6056755",
  onclickaB6050154: "onclicka-b-6050154",
  adsteraBB8cf70b3ea2d5849d9d0a40c0c9341c7:
    "adstera-b-b8cf70b3ea2d5849d9d0a40c0c9341c7",
  aadsB2369669: "aads-b-2369669",
  adsgramV6013: "adsgram-v-6013",
  adsgramV6008: "adsgram-v-6008",
  adsgramV6010: "adsgram-v-6010",
  adsgramV8380: "adsgram-v-8380",
  adsgramV6011: "adsgram-v-6011",
  adsgramV6018: "adsgram-v-6018",
  onclickaV6056936: "onclicka-v-6056936",
  sonarVApp7b73f313Bonuses: "sonar-v-app_7b73f313*bonuses",
  sonarVApp7b73f313MiniGames: "sonar-v-app_7b73f313*mini_games",
  adsgramI7928: "adsgram-i-7928",
  mybidV2007067: "mybid-v-2007067",
};

export const adsRender = {
  onclicka: {
    init: (id) => initOnClicka(id),
    render: (id) => <div data-banner-id={id}></div>,
  },
  adstera: {
    init: (key) => initAdsteraAd(key),
    render: (key) => <div id={key}></div>,
  },
  aads: {
    render: (id) => (
      <iframe
        title="banner"
        data-aa={id}
        src={`//ad.a-ads.com/${id}?size=300x250`}
        style={{
          width: "300px",
          height: "250px",
          border: "0px",
          padding: "0",
          overflow: "hidden",
          backgroundColor: "transparent",
        }}
      ></iframe>
    ),
  },
};

