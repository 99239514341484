import React from "react";

import "./FriendsRewards.scss";
import { getResIconImg } from "assets/images";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";

const {
  getNowText,
  forRegistrationText,
  fromEachCollectionText,
  lvlText,
  activityText,
} = TRANSLATIONS.freinds;

const FriendsRewards = () => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  return (
    <div className="friends-rewards">
      <h3 className="friends-rewards__title">{getNowText[siteLanguage]}</h3>
      <div className="friends-rewards__list f-jcsb">
        <div className="friends-rewards__item big f-center-center">
          <div className="friends-rewards__value">+50</div>
          <div className="friends-rewards__icon">
            <img src={getResIconImg} alt="" />
          </div>
          <p className="friends-rewards__text">
            {forRegistrationText[siteLanguage]}
          </p>
        </div>
        <div className="friends-rewards__item big f-center-center">
          <p className="friends-rewards__text">{activityText[siteLanguage]}</p>
        </div>
        <div className="friends-rewards__item">
          <div className="friends-rewards__value">1%</div>
          <p className="friends-rewards__text">
            {fromEachCollectionText[siteLanguage]}
          </p>
        </div>

        <div className="friends-rewards__item">
          <div className="friends-rewards__value">
            0,1% ({lvlText[siteLanguage]} 2)
          </div>
          <p className="friends-rewards__text">
            {fromEachCollectionText[siteLanguage]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FriendsRewards;
