import {
  resourcesPopupBottomIcon,
  resourcesPopupLeftFigureIcon,
  resourcesPopupMoreIcon,
  resourcesPopupRightFigureIcon,
  resourcesPopupValueBgIcon,
} from "assets/svg";
import { TRANSLATIONS } from "assets/translations";
import MainBtn from "components/layout/MainBtn/MainBtn";
import Popup from "components/layout/Popup/Popup";
import Svg from "components/layout/Svg/Svg";
import { statsImages } from "constants/stats";
import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./BuildsGetBonusModal.scss";
import { formatMilliseconds } from "utils/formatMilliseconds";

const { availableForCollectionText, collectText, willBeAvailableText } =
  TRANSLATIONS.resourcePopup;

const BuildsGetBonusModal = ({
  onClose,
  activePopup,
  timer,
  isEnable,
  bonusValue,
  collectBonus,
  incomeType
}) => {
  const dispatch = useDispatch();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const [loading, setLoading] = useState(false);

  const onCollect = async () => {
    setLoading(true);
    await dispatch(collectBonus());
    setLoading(false);
    onClose();
  };

  return (
    <Popup onClose={onClose} activePopup={activePopup}>
      <div className="builds-get-bonus-modal">
        <TransitionProvider
          style={TransitionStyleTypes.opacity}
          inProp={loading}
          className="builds-get-bonus-modal__loader"
        ></TransitionProvider>
        <h2 className="builds-get-bonus-modal__title">
          {!isEnable
            ? willBeAvailableText[siteLanguage]
            : availableForCollectionText[siteLanguage]}
        </h2>
        <div className="builds-get-bonus-modal__wrap f-jcsb">
          <div className="builds-get-bonus-modal__figure">
            <Svg id={resourcesPopupLeftFigureIcon} />
          </div>
          <div className="builds-get-bonus-modal__value f-center-center">
            <div
              className={`builds-get-bonus-modal__value-content f-center ${
                !isEnable ? "timer" : ""
              }`}
            >
              {isEnable ? (
                <>
                  <img src={statsImages[incomeType || "matter"]} alt="" />
                  {bonusValue.toFixed(3)}
                </>
              ) : (
                <span className="builds-get-bonus-modal__timer">
                  {formatMilliseconds(timer)}
                </span>
              )}
            </div>

            <div className="builds-get-bonus-modal__value-bg">
              <Svg id={resourcesPopupValueBgIcon} />
            </div>
          </div>
          <div className="builds-get-bonus-modal__figure">
            <Svg id={resourcesPopupRightFigureIcon} />
          </div>
        </div>
        <div className="builds-get-bonus-modal__more">
          <Svg id={resourcesPopupMoreIcon} />
        </div>
        <div className="builds-get-bonus-modal__nav f-center-jcsb">
          <MainBtn disabled={!isEnable} onClick={onCollect} asSecondary>
            {collectText[siteLanguage]}
          </MainBtn>
        </div>

        <div className="builds-get-bonus-modal__bottom">
          <Svg id={resourcesPopupBottomIcon} />
        </div>
      </div>
    </Popup>
  );
};

export default BuildsGetBonusModal;
