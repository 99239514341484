import React, { useEffect, useRef, useState } from "react";
import Svg from "components/layout/Svg/Svg";

import {
  imagePuzzlePlayerArrowIcon,
  imagePuzzlePlayerMuteIcon,
  imagePuzzlePlayerUnmuteIcon,
} from "assets/svg";
import "./ImagePuzzlePlayer.scss";
import {
  fetchPuzzleAudioFiles,
  PUZZLE_MEDIA_BASE_PATH,
} from "../../../../store/actions/puzzle";
import { shuffleArray } from "utils/shuffleArray";

const ImagePuzzlePlayer = () => {
  const [audios, setAudios] = useState([]);
  const [curAudioIndex, setCurAudioIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await fetchPuzzleAudioFiles();
        if (Array.isArray(data) && data.length > 0) {
          setAudios(shuffleArray(data));
        }
      } catch (error) {
        console.error("Failed to fetch audio files:", error);
      }
    })();
  }, []);

  const onPlayPause = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch(() => setIsPlaying(false)); // Handle autoplay issues
    }
  };

  useEffect(() => {
    if (!audioRef.current) return;

    const audioElement = audioRef.current;
    const updateState = () => setIsPlaying(!audioElement.paused);

    audioElement.addEventListener("play", updateState);
    audioElement.addEventListener("pause", updateState);

    return () => {
      audioElement.removeEventListener("play", updateState);
      audioElement.removeEventListener("pause", updateState);
    };
  }, []);

  const onChange = (isPrev) => {
    if (!audioRef.current || audios.length === 0) return;

    setCurAudioIndex((prevState) => {
      if (isPrev) {
        return prevState === 0 ? audios.length - 1 : prevState - 1;
      }
      return prevState === audios.length - 1 ? 0 : prevState + 1;
    });

    setTimeout(() => {
      if (audioRef.current) {
        audioRef.current.play();
      }
    }, 100); // Small delay to ensure state updates before playing
  };

  return (
    <>
      <audio
        ref={audioRef}
        src={
          audios.length > 0
            ? `${PUZZLE_MEDIA_BASE_PATH}/audio/${audios[curAudioIndex]}`
            : ""
        }
        onEnded={() => onChange(false)} // Auto-play next on end
      ></audio>

      <div className="image-puzzle-player">
        <button
          className="image-puzzle-player__btn"
          onClick={() => onChange(true)}
        >
          <Svg id={imagePuzzlePlayerArrowIcon} />
        </button>
        <button
          onClick={onPlayPause}
          className={`image-puzzle-player__btn ${!isPlaying ? "playing" : ""}`}
        >
          <Svg
            id={
              !isPlaying
                ? imagePuzzlePlayerMuteIcon
                : imagePuzzlePlayerUnmuteIcon
            }
          />
        </button>
        <button
          className="image-puzzle-player__btn next"
          onClick={() => onChange(false)}
        >
          <Svg id={imagePuzzlePlayerArrowIcon} />
        </button>
      </div>
    </>
  );
};

export default ImagePuzzlePlayer;
