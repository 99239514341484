import TransitionProvider, {
  TransitionStyleTypes,
} from "providers/TransitionProvider";
import React, { useEffect, useRef, useState } from "react";

import "./AppLoader.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  fecthRandomHint,
  fecthRandomSplashImage,
  PUZZLE_MEDIA_BASE_PATH,
} from "store/actions/puzzle";

const AppLoader = ({ loading }) => {
  const location = useLocation();
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const [image, setImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [hint, setHint] = useState(null);
  const [timerFinished, setTimerFinished] = useState(false);
  const videoRef = useRef(null);

  const isImage = image && /\.(jpg|jpeg|png|gif|webp)$/i.test(image);
  const isVideo = image && /\.(mp4|webm|ogg)$/i.test(image);

  const fullSplashUrl = `${PUZZLE_MEDIA_BASE_PATH}/splash/${image}`;

  useEffect(() => {
    if (imageLoaded) {
      setTimeout(() => {
        setTimerFinished(true);
      }, 3000);
    }
  }, [imageLoaded]);
  useEffect(() => {
    if (image) {
      setTimeout(() => {
        setImageLoaded(true);
      }, 6000);

      if (videoRef.current) videoRef.current.play();
    }
  }, [image]);

  useEffect(() => {
    (async () => {
      try {
        const { image } = await fecthRandomSplashImage();
        setImage(image);
      } catch (error) {}
    })();
    (async () => {
      const { hint } = await fecthRandomHint();
      setHint(hint);
    })();
  }, []);

  return (
    <TransitionProvider
      style={TransitionStyleTypes.opacity}
      inProp={
        (loading || !timerFinished) && !location.search.includes("popup=open")
      }
      className="app-loader"
    >
      <div
        className={`app-loader__loader ${image && imageLoaded ? "hidden" : ""}`}
      ></div>
      {image && (
        <>
          {isImage && (
            <img
              onLoad={() => setImageLoaded(true)}
              src={fullSplashUrl}
              alt="loader"
              className={`app-loader__img container ${
                !imageLoaded ? "hidden" : ""
              }`}
            />
          )}
          {isVideo && (
            <video
              ref={videoRef}
              onCanPlayThrough={() => setImageLoaded(true)}
              autoPlay={true}
              loop={true}
              controls={false}
              playsInline
              muted={true}
              src={fullSplashUrl}
              alt="loader"
              className={`app-loader__img container `}
            />
          )}
        </>
      )}
      {hint && (
        <div className="container app-loader__hint">
          <p className={`app-loader__hint-text`}>{hint[siteLanguage]}</p>
        </div>
      )}
    </TransitionProvider>
  );
};

export default AppLoader;
