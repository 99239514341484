/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

export const useTimer = (bonusTime, onChange) => {
  const dispatch = useDispatch();

  const [shouldTimerWork, setShouldTimerWork] = useState(false);

  const bonusTimeRef = useRef(bonusTime);
  const intervalRef = useRef(null);

  useEffect(() => {
    bonusTimeRef.current = bonusTime;

    if (!shouldTimerWork && bonusTime) {
      setShouldTimerWork(true);
    } else if (shouldTimerWork && !bonusTime) {
      setShouldTimerWork(false);
    }
  }, [bonusTime]);

  useEffect(() => {
    const harvest = () => {
      if (bonusTimeRef.current) {
        dispatch(onChange());
      }
    };

    if (shouldTimerWork) {
      intervalRef.current = setInterval(() => {
        harvest();
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTimerWork]);
};
