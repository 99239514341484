import { TRANSLATIONS } from "assets/translations";
import MainBtn from "components/layout/MainBtn/MainBtn";
import Popup from "components/layout/Popup/Popup";
import React from "react";
import { useSelector } from "react-redux";

import "./HackTerminalModal.scss";
import { formatNumber } from "utils/formatNumber";

const {
  winText,
  loseText,
  playAgainText,
  resourcesText,
  matterText,
  freeBonusText,
  youReceivedText,
} = TRANSLATIONS.hackingTerminal;

const bonusTypesTexts = {
  resources: resourcesText,
  "free bonus": freeBonusText,
  matter: matterText,
};

const HackTerminalModal = ({
  activePopup,
  isLose,
  onReset,
  bonusValue,
  bonusType,
}) => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  return (
    <Popup activePopup={activePopup} onClose={onReset}>
      <div className="hack-terminal-modal">
        <h3 className="hack-terminal-modal__title">
          {isLose ? loseText[siteLanguage] : winText[siteLanguage]}
        </h3>
        {!isLose && bonusValue && bonusType && (
          <div className="hack-terminal-modal__bonus">
            {youReceivedText[siteLanguage]}: {formatNumber(bonusValue,3)}{" "}
            {bonusTypesTexts?.[bonusType]?.[siteLanguage]}
          </div>
        )}
        <MainBtn onClick={onReset}>{playAgainText[siteLanguage]}</MainBtn>
      </div>
    </Popup>
  );
};

export default HackTerminalModal;
