import { adsRender } from "constants/adRouter";
import { getPlatformType } from "./getPlatformType";



export const getCurAd = (routes,spotId) => {
      const tg = window.Telegram?.WebApp

      const isMobile = getPlatformType(tg)

      const platformType = isMobile ? "mobile" : "desktop";
     const [service, type, id] = routes[spotId][platformType].split("-");
      const curAd = adsRender[service];

      return {curAd,service, type, id}
}