import React, { forwardRef } from "react";
import Svg from "../Svg/Svg";
import { mainBtnBgIcon, secondaryBtnBgIcon } from "assets/svg";

import "./MainBtn.scss";

const MainBtn = forwardRef(
  ({ className, children, type, innerClass, asSecondary, ...args }, ref) => {
    return (
      <button
        ref={ref}
        type={type || "button"}
        className={`main-btn f-center-center ${className || ""}`}
        {...args}
      >
        <span className={`f-center-center ${innerClass || ""}`}>
          {children}
        </span>

        <div className="main-btn__bg">
          <Svg id={asSecondary ? secondaryBtnBgIcon : mainBtnBgIcon} />
        </div>
      </button>
    );
  }
);

export default MainBtn;
