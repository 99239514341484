import React from "react";

import "./ImagePuzzleHeader.scss";
import ImagePuzzlePlayer from "./ImagePuzzlePlayer/ImagePuzzlePlayer";
import Svg from "../../layout/Svg/Svg";
import { hackterminalCloseIcon } from "assets/svg";
import { useNavigate } from "react-router-dom";
import { tasksPagePath } from "router/path";
import { PUZZLE_PIECES_COUNT } from "../../../constants/imagePuzzle";
import { TRANSLATIONS } from 'assets/translations';
import { useSelector } from 'react-redux';


const {
  timeLeftText,
  secondsText,
  rewardsText,
  piecesCollectedText,
  outOfText,
} = TRANSLATIONS.imagePuzzle

const ImagePuzzleHeader = ({
  timer,
  collectedPeacesCount,
  setIsRewardsActive,
  isRewardsActive,
}) => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);
  const navigate = useNavigate();
  return (
    <header className="image-puzzle-header">
      <div className="image-puzzle-header__main">
        <div className="image-puzzle-header__player-wrapper">
          <ImagePuzzlePlayer />
        </div>
        <h1 className="image-puzzle-header__title">MINI PUZZLE</h1>
        <div className="image-puzzle-header__close-btn-wrapper">
          <button
            onClick={() => navigate(tasksPagePath)}
            className="image-puzzle-header__close-btn"
          >
            <Svg id={hackterminalCloseIcon} />
          </button>
        </div>
      </div>
      <div className="image-puzzle-header__info">
        <div className="image-puzzle-header__info-desk left">
          {timeLeftText[siteLanguage]} <br /> ({secondsText[siteLanguage]}): {timer}
        </div>
        <button
          onClick={() => setIsRewardsActive((prevState) => !prevState)}
          className={`image-puzzle-header__awards-btn ${
            isRewardsActive ? "active" : ""
          }`}
        >
          {rewardsText[siteLanguage]}
        </button>
        <div className="image-puzzle-header__info-desk right">
          {piecesCollectedText[siteLanguage]}: <br />
          {collectedPeacesCount} {outOfText[siteLanguage]} {PUZZLE_PIECES_COUNT}
        </div>
      </div>
    </header>
  );
};

export default ImagePuzzleHeader;
