import { statsImages } from "constants/stats";
import { formatNumber } from "../../../../utils/formatNumber";
import Svg from "../../../layout/Svg/Svg";
import {
  taskListRewardBgIcon,
  taskSubscribeBtnBgIcon,
  taskGetBtnBgIcon,
} from "../../../../assets/svg";
import { formatMilliseconds } from "../../../../utils/formatMilliseconds";
import {
  startWallgramTask,
  subscribeClickLinkBot,
} from "store/actions/profile";
import { TRANSLATIONS } from "assets/translations";

import "./RegularTaskItem.scss";
import { useTelegram } from "hooks/useTelegram";
import { useDispatch } from "react-redux";

const { subscribeText, getText } = TRANSLATIONS.tasks;

const RegularTaskItem = ({
  name,
  keyName,
  description,
  bonus,
  bonus_size,
  link_name,
  link,
  is_subscribed,
  time_left,
  siteLanguage,
  is_wallgram,
  bonus_type,
  onSubscribe,
  is_teddy,
  subscribeTeddyTask,
  id,
  campaignId,
  image,
}) => {
  // eslint-disable-next-line no-undef
  const dispatch = useDispatch();
  const tg = useTelegram();

  const isClickBot = !link_name && !is_wallgram;

  return (
    <div className="regular-rask-item">
      <div className="regular-rask-item__main">
        <div className="f-center">
          {image && (
            <img
              src={image}
              alt="task"
              className="regular-rask-item__mainImg"
            />
          )}
          <div>
            <h3 className="regular-rask-item__title">{name}</h3>
            <p className="regular-rask-item__text">{description}</p>
          </div>
        </div>
        <div className="regular-rask-item__offer f-center-jcsb">
          <div className="regular-rask-item__reward f-center-center">
            <img src={statsImages[bonus_type || "resources"]} alt="" />
            {formatNumber(
              bonus || bonus_size,
              bonus_type === "matter" ? 3 : undefined
            )}
            <div className="regular-rask-item__reward-bg">
              <Svg id={taskListRewardBgIcon} />
            </div>
          </div>
          <div className="regular-rask-item__btns f-center">
            {time_left ? (
              <p className="regular-rask-item__item-timer">
                {formatMilliseconds(time_left)}
              </p>
            ) : (
              <>
                {!is_subscribed && (
                  <>
                    {is_teddy ? (
                      <a
                        href={link}
                        target="_blank"
                        onClick={() => {
                          if (is_teddy && subscribeTeddyTask) {
                            subscribeTeddyTask(id);
                          }
                        }}
                        type="button"
                        className="regular-rask-item__btn f-center-center "
                        rel="noreferrer"
                      >
                        {subscribeText[siteLanguage]}
                        <div className="regular-rask-item__btn-bg">
                          <Svg id={taskSubscribeBtnBgIcon} />
                        </div>
                      </a>
                    ) : (
                      <button
                        onClick={() => {
                          if (isClickBot) {
                            dispatch(subscribeClickLinkBot(link, keyName));
                          }
                          if (is_wallgram) {
                            dispatch(startWallgramTask(id, campaignId));
                          }

                          // if (process.env.NODE_ENV !== "development") {
                          if (link && !link.includes("t.me")) {
                            tg.openLink(link);
                          } else {
                            tg.openTelegramLink(
                              link || `https://t.me/${name.slice(1)}`
                            );
                          }
                          // }
                        }}
                        type="button"
                        className="regular-rask-item__btn"
                      >
                        {subscribeText[siteLanguage]}
                        <div className="regular-rask-item__btn-bg">
                          <Svg id={taskSubscribeBtnBgIcon} />
                        </div>
                      </button>
                    )}
                  </>
                )}
                {((isClickBot && is_subscribed) || !isClickBot) && (
                  <button
                    onClick={() => {
                      if (is_teddy) {
                        onSubscribe({ teddyTaskId: id });
                      } else if (is_wallgram) {
                        onSubscribe({ wallgramTaskId: id });
                      } else if (keyName) {
                        onSubscribe({ name: keyName, link });
                      } else onSubscribe({ name });
                    }}
                    type="button"
                    className="regular-rask-item__btn"
                  >
                    {getText[siteLanguage]}
                    <div className="regular-rask-item__btn-bg">
                      <Svg id={taskGetBtnBgIcon} />
                    </div>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegularTaskItem;
