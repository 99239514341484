export const mobMenuTopIcon = "mobMenuTop";
export const menuBgDefaultIcon = "menuBgDefault";
export const menuBgActiveIcon = "menuBgActive";
export const mobileMenuBorderIcon = "mobileMenuBorder";
export const menuAchievmentsIcon = "menuAchievments";
export const menuBuildsIcon = "menuBuilds";
export const menuMainIcon = "menuMain";
export const menuTasksIcon = "menuTasks";
export const menuFriendsIcon = "menuFriends";
export const menuBottomIcon = "menuBottom";
export const userIcon = "user";
export const avatarBgIcon = "avatarBg";
export const headerBgIcon = "headerBg";
export const settingsIcon = "settings";
export const resourcesIcon = "resources";
export const mainStatItemBgIcon = "mainStatItemBg";
export const mainStatsBgIcon = "mainStatsBg";
export const buildsModeSwithBgIcon = "buildsModeSwithBg";
export const buildsTabBarBgIcon = "buildsTabBarBg";
export const buildsUpgradeBgIcon = "buildsUpgradeBg";
export const buildsProgressCircleIcon = "buildsProgressCircle";
export const tasksTabBarBgIcon = "tasksTabBarBg";
export const taskListRewardBgIcon = "taskListRewardBg";
export const taskSubscribeBtnBgIcon = "taskSubscribeBtnBg";
export const taskGetBtnBgIcon = "taskGetBtnBg";
export const taskDailyBgIcon = "taskDailyBg";
export const friendsEarnBtnBgIcon = "friendsEarnBtnBg";
export const friendsEarnBgIcon = "friendsEarnBg";
export const friendsStatusBgIcon = "friendsStatusBg";
export const friendsStatusFigureIcon = "friendsStatusFigure";
export const friendsCopyIcon = "friendsCopy";
export const friendsShareIcon = "friendsShare";
export const resourcesPopupLeftFigureIcon = "resourcesPopupLeftFigure";
export const resourcesPopupRightFigureIcon = "resourcesPopupRightFigure";
export const resourcesPopupValueBgIcon = "resourcesPopupValueBg";
export const resourcesPopupMoreIcon = "resourcesPopupMore";
export const resourcesPopupBottomIcon = "resourcesPopupBottom";
export const mainMineBlockParamsBgIcon = "mainMineBlockParamsBg";
export const mainMineBlockLeftTimeBgIcon = "mainMineBlockLeftTimeBg";
export const mainMineBlockRightTimeBlockIcon = "mainMineBlockRightTimeBlock";
export const permanentBonusBgIcon = {
  eng: "permanentBonusBgEng",
  rus: "permanentBonusBgRus",
};
export const speedBonusBgIcon = {
  eng: "speedBonusBgEng",
  rus: "speedBonusBgRus",
};
export const resouceBonusBgIcon = {
  eng: "resouceBonusBgEng",
  rus: "resouceBonusBgRus",
};
export const resetProgressBgIcon = {
  eng: "resetProgressBgEng",
  rus: "resetProgressBgRus",
};
export const mainCollectBgIcon = "mainCollectBg";
export const setAllBgIcon = "setAllBg";
export const inputBgIcon = "inputBg";
export const mainBtnBgIcon = "mainBtnBg";
export const secondaryBtnBgIcon = "secondaryBtnBg";
export const backIcon = "back";
export const selectArrowIcon = "selectArrow";

// hack terminal
export const numpadArrowRightIcon = "numpadArrowRight";
export const numpadArrowLeftIcon = "numpadArrowLeft";
export const historyToggleIcon = "historyToggle";
export const hackterminalCloseIcon = "hackterminalClose";
export const hackTerminalBackIcon = "hackTerminalBack";

// image puzzle
export const imagePuzzlePlayerArrowIcon = "imagePuzzlePlayerArrow";
export const imagePuzzlePlayerUnmuteIcon = "imagePuzzlePlayerUnmute";
export const imagePuzzlePlayerMuteIcon = "imagePuzzlePlayerMute";
