import React from "react";
import { useSelector } from "react-redux";

import { poolMatterBonus } from "store/actions/profile";
import BuildsGetBonusModal from "../BuildsGetBonusModal/BuildsGetBonusModal";

const BuildsMatterPollModal = ({ onClose, activePopup }) => {
  const poolBonusValue = useSelector((state) => state.profile.poolBonusValue);
  const poolTimer = useSelector((state) => state.profile.poolTimer);
  const isPoolEnable = useSelector((state) => state.profile.isPoolEnable);

  return (
    <BuildsGetBonusModal
      activePopup={activePopup}
      onClose={onClose}
      collectBonus={poolMatterBonus}
      timer={poolTimer}
      bonusValue={poolBonusValue}
      isEnable={isPoolEnable}
    />
  );
};

export default BuildsMatterPollModal;
