import React from "react";

import "./ImagePuzzleAwards.scss";
import { TRANSLATIONS } from "assets/translations";
import { useSelector } from "react-redux";

const {
  gameRulesText,
  bonusTypeText,
  resourcesFor15SecText,
  TMText,
  freeBonusText,
  closeText,
} = TRANSLATIONS.imagePuzzle;

const ImagePuzzleAwards = ({ setIsRewardsActive }) => {
  const siteLanguage = useSelector((state) => state.ui.siteLanguage);

  return (
    <div className="image-puzzle-awards">
      <h3 className="image-puzzle-awards__title">
        {gameRulesText[siteLanguage]}
      </h3>
      <ul className="image-puzzle-awards__list">
        <li className="image-puzzle-awards__list-item">
          {bonusTypeText[siteLanguage]} – %
        </li>
        <li className="image-puzzle-awards__list-item">
          {resourcesFor15SecText[siteLanguage][0]} 15{" "}
          {resourcesFor15SecText[siteLanguage][1]} – 10
        </li>
        <li className="image-puzzle-awards__list-item">
          {resourcesFor15SecText[siteLanguage][0]} 30{" "}
          {resourcesFor15SecText[siteLanguage][1]} – 20
        </li>
        <li className="image-puzzle-awards__list-item">
          {resourcesFor15SecText[siteLanguage][0]} 45{" "}
          {resourcesFor15SecText[siteLanguage][1]} – 10
        </li>
        <li className="image-puzzle-awards__list-item">
          {freeBonusText[siteLanguage]} – 10
        </li>
        <li className="image-puzzle-awards__list-item">
          0,001 {TMText[siteLanguage]} – 25
        </li>
        <li className="image-puzzle-awards__list-item">
          0,002 {TMText[siteLanguage]} – 15
        </li>
        <li className="image-puzzle-awards__list-item">
          0,003 {TMText[siteLanguage]} – 10
        </li>
      </ul>
      <button
        onClick={() => setIsRewardsActive(false)}
        className="image-puzzle-awards__btn"
      >
        {closeText[siteLanguage]}
      </button>
    </div>
  );
};

export default ImagePuzzleAwards;
